export default class ProjectService {
    constructor(http, tokenStorage) {
        this.http = http;
        this.tokenStorage = tokenStorage;
    }

    /**
     * 프로젝트 리스트
     */
    async getProjectList(status) {
        return this.http.axios(`/projects?status=${status}`, {
            method: 'GET',
            headers: this.getHeaders(),
        });
    }

    /**
     * 관리자 프로젝트 리스트
     */
     async getAdminProjectList(status) {
        return this.http.axios(`/admin/projects?status=${status}`, {
            method: 'GET',
            headers: this.getHeaders(),
        });
    }

    /**
     * 프로젝트 현황 조회
     */
    async getProjectStatList(role) {
        return this.http.axios(`/accounts?role=${role}`, {
            method: 'GET',
            headers: this.getHeaders(),
    });
    }

    /**
     * 참여 가능한 프로젝트 조회
     */
    async viewProject(projectNo) {

        return this.http.axios(`/project/${projectNo}/available/`, {
            method: 'GET',
            headers: this.getHeaders(),
        });
    }

    /**
     * 참가한 프로젝트 정보 조회
     */
    async userProjectInfo(projectNo) {

        return this.http.axios(`/projects/${projectNo}`, {
            method: 'GET',
            headers: this.getHeaders(),
        });
    }

    /**
     * 관리자 참가한 프로젝트 정보 조회
     */
     async adminProjectInfo(projectNo) {

        return this.http.axios(`/admin/projects/${projectNo}`, {
            method: 'GET',
            headers: this.getHeaders(),
        });
    }

    async endProjectInfo(projectNo) {

        return this.http.axios(`/project/${projectNo}/closed`, {
            method: 'GET',
            headers: this.getHeaders(),
        });
    }

    /**
     * 프로젝트 신청 리스트
     */
    async getProjectRequestList() {

        return this.http.axios(`/projects/available`, {
            method: 'GET',
            headers: this.getHeaders(),
        });
    }

    /**
     * 프로젝트 신청 요청
     */
    async projectInsert(projectNo,type) {

        return this.http.axios(`/project/participate`, {
            method: 'POST',
            headers: this.getHeaders(),
            data: { projectNo, "status":type },
        });
    }

    /**
     * 프로젝트 통계 조회
     */
     async projectStatistics(projectNo) {

        return this.http.axios(`/projects/${projectNo}/statistics`, {
            method: 'GET',
            headers: this.getHeaders()
        });
    }

    /**
     * 프로젝트 정산 조회
     */
     async getAccountList(projectNo) {

        return this.http.axios(`/projects/${projectNo}/accounts`, {
            method: 'GET',
            headers: this.getHeaders()
        });
    }

    /**
     *  내 진행 중 작업
     */
    async getMyJobList(projectNo) {
        return this.http.axios(`/task-folders?status=2&projectNo=${projectNo}`, {
            method: 'GET',
            headers: this.getHeaders(),
        });
    }

    /**
     * 내 완료된 작업
     */
    async getMyCompleteJob(projectNo) {
        return this.http.axios(`/task-folders?status=3&projectNo=${projectNo}`, {
            method: 'GET',
            headers: this.getHeaders(),
        });
    }

    /**
     * 검수 대상 목록
     */
    async getInspcTrgtsList(projectNo) {
        return this.http.axios(`/task-folders?status=4&projectNo=${projectNo}`, {
            method: 'GET',
            headers: this.getHeaders(),
        });
    }

    /*
    * 작업 대상 목록
    */
    async getAnnotationList(projectNo) {
        return this.http.axios(`/task-folders?status=1&projectNo=${projectNo}`, {
            method: 'GET',
            headers: this.getHeaders(),
        });
    }

    
    /**
     * 내 진행 중 검수
     */
     async getMyProgressInspection(projectNo) {
        return this.http.axios(`/task-folders?status=5&projectNo=${projectNo}`, {
            method: 'GET',
            headers: this.getHeaders(),
        });
    }

    /**
     * 내 완료된 검수
     */
    async getMyCompletedInspection(projectNo) {
        return this.http.axios(`/task-folders?status=6&projectNo=${projectNo}`, {
            method: 'GET',
            headers: this.getHeaders(),
        });
    }

    /**
     * 내 어드민
     */
     async getJobAdmin(projectNo, status) {
        return this.http.axios(`/admin/task-folders?projectNo=${projectNo}&status=${status}`, {
            method: 'GET',
            headers: this.getHeaders(),
        });
    }

    /*
    * 작업(검수) 시작
    */
    async onJobStart(projectNo,taskFolderNo,role) {
        return this.http.axios(`/task-folders/${taskFolderNo}?role=${role}&process=START&projectNo=${projectNo}`, {
            method: 'PATCH',
            headers: this.getHeaders(),
        });
    }

    /**
     *  작업완료 호출
     */
     onJobEnd(projectNo,taskFolderNo,role) {
        return this.http.axios(`/task-folders/${taskFolderNo}?role=${role}&process=COMPLETE&projectNo=${projectNo}`, {
            method: 'PATCH',
            headers: this.getHeaders(),
        });
    }

    
    /**
     *  관리자 작업완료 호출
     */
     onAdminJobEnd(taskFolderNo) {
        return this.http.axios(`/admin/task-folders/${taskFolderNo}`, {
            method: 'POST',
            headers: this.getHeaders(),
        });
    }

    getHeaders() {
        const token = this.tokenStorage.getToken();
        return {
            Authorization: `Bearer ${token}`,
        };
    }

}
