import React, { useEffect, useState } from 'react';
import {message} from 'antd';
import { useParams, useHistory } from "react-router-dom";

import {
    Row,
    Col,
    Space,
    Descriptions,
    PageHeader,
    Button,
} from "antd";

import { 
    Dialog, 
    DialogActions, 
    DialogTitle,
    DialogContent,
    DialogContentText
} from "@material-ui/core";

/**
 * 프로젝트 신청 상세보기
 */
const ProjectRequestView = ({ projectService }) => {
    const [project, setProject] = useState({});
    const [open, setOpen] = React.useState(false);
    const [type, setType] = useState(null);
    const [korType, setKorType] = useState(null);

    const { projectNo } = useParams();
    const history = useHistory();


    useEffect(() => {
        projectService.viewProject(projectNo).then(r => {

            console.log(r);
            setProject(r);
        });

    }, [projectService]);

    const onProjectInsert = (type) => {
        //alert("신청버튼 호출 --> " + type);

        projectService.projectInsert(projectNo, type).then(() => {
            message.info("정상적으로 신청 되었습니다.");
            history.push('/projectRequest');
        });

    }

    const handleClickOpen = (korType,type) => {

        setKorType(korType);
        setType(type);
        setOpen(true);
        
      };
    
      const handleClose = () => {
        setOpen(false);
      };

    return (
        <>
            <PageHeader
                className="site-page-header"
                title={project.title}
            />
            <Descriptions bordered>
                <Descriptions.Item label="프로젝트 명칭">{project.title}</Descriptions.Item>
                <Descriptions.Item label="목표 수량" span={2}>{project.targetAmount}개</Descriptions.Item>
                <Descriptions.Item label="프로젝트 설명" span={3}>{project.content}</Descriptions.Item>
                <Descriptions.Item label="프로젝트 기간" span={3}>
                    <Row>
                        <Col span={8}>시작일 : {project.startDate}</Col>
                        <Col span={8}>종료일 : {project.endDate}</Col>
                        <Col span={8}>남은 기간 : {project.remainingDate}</Col>
                    </Row>
                </Descriptions.Item>
            </Descriptions>
            <br />
            <PageHeader
                className="site-page-header"
                title="담당자 정보"
            />
            <Descriptions bordered>
                <Descriptions.Item label="담당자 이름">{project.mngrName}</Descriptions.Item>
                <Descriptions.Item label="전화번호" span={2}>{project.mngrPhone1}</Descriptions.Item>
                <Descriptions.Item label="휴대전화번호" >{project.mngrPhone1}</Descriptions.Item>
                <Descriptions.Item label="이메일주소" >{project.mngrEmail1}@{project.mngrEmail2}</Descriptions.Item>
            </Descriptions>
            <br />

            {project.available && (
                <Space size={"large"}>
                    <Button type="primary" style={{ marginRight: "10px" }} onClick={() => handleClickOpen("작업자", "UR")}>작업자 신청</Button>
                    <Button onClick={() => handleClickOpen("검수자", "MR")}>검수자 신청</Button>
                </Space>
            )
            }

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`알림`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        해당 프로젝트를 {korType}로 신청하시겠습니까?
          </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        취소
          </Button>
                    <Button onClick={() => onProjectInsert(type)} color="primary" autoFocus>
                        확인
          </Button>
                </DialogActions>
            </Dialog>

        </>
    );
};

export default ProjectRequestView;
