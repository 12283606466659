import React, {Component} from 'react';
import loading from "../../img/Loading.svg";

class Loading extends Component {
    render() {
        return (
            <div className="spinner" style = {{display: 'block', textAlign: 'center', marginTop: 30}}>
                <img src={loading} alt="Loading" />
            </div>
        );
    }
}

export default Loading;
