import React, { useState } from 'react';


import crypto from 'crypto';

//antd
import { Form, Input, Button, message } from 'antd';

//style
import '../components/css/Login.css'

//img
import logo from "../img/login-logo.png";



const Login = ({ onSignUp, onLogin }) => {
  const [signup, setSignup] = useState(false);
  const [userId, setUserId] = useState('');
  const [userPw, setUserPw] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [url, setURL] = useState('');
  const [text, setText] = useState('');
  const [isAlert, setIsAlert] = useState(false);

    const onFinish = (values) => {
        const cryptoPw = crypto.createHash('sha256').update(values.userPw).digest('base64');
        onLogin(values.userId, cryptoPw).catch(error => {
            message.error(error.message);
        });
    };

  const setError = (error) => {
    setText(error.toString());
    setIsAlert(true);
  };

  return (
      <div className="wrap">
          <div className="content">

              <div className="login-container">

                  <div className="login-content">

                      <center className="login-logo">
                          <img width="280" src={logo} />
                      </center>

                      <Form onFinish={onFinish}>
                          <Form.Item
                              className="loginInput"
                              name="userId"
                              rules={[
                                  {
                                      required: true,
                                      message: '아이디를 입력해주세요!',
                                  },
                              ]}
                          >
                              <Input placeholder="아이디" bordered={false} />
                          </Form.Item>

                          <Form.Item
                              className="loginInput"
                              name="userPw"
                              rules={[
                                  {
                                      required: true,
                                      message: '비밀번호를 입력해주세요!',
                                  },
                              ]}
                          >
                              <Input.Password placeholder="비밀번호" bordered={false}/>
                          </Form.Item>

                          <Form.Item>
                              <Button id="BtnLogin" className="login-form-button" type="text" htmlType="submit">
                                  로그인
                              </Button>


                          </Form.Item>
                      </Form>
                  </div>
              </div>

          </div>
      </div>
  );
};

export default Login;
