import React, {memo, useEffect, useState} from 'react';
import Loading from "../../common/Loading";
import ReactImageAnnotate from "react-image-annotate";
import { Button, notification } from 'antd';
import { useHistory, useParams} from "react-router-dom";
import * as keypointDefinition from '../../../keypointDefinition.js';
import makeImmutable from "seamless-immutable";
import axios from "axios";
import { Skeleton } from '@material-ui/lab';

/**
 * 어노테이션 관리자 페이지(비디오)
 * @returns {JSX.Element}
 * @constructor
 */
const AnnotationVideoAdmin = memo(({ projectInfo, annotationService, workFolderInfo }) => {
 
  const history = useHistory();

  //각종 param 상수
  const projectNo = projectInfo.projectNo,          //프로젝트 PK
        role = workFolderInfo.role,                 //작업자/검수자 구분
        annotationType = workFolderInfo.dataType,   //작업유형 (OBJ,FL,OCR,...)
        workFolderNo = workFolderInfo.taskFolderNo, //작업폴더 정보 오브젝트
        jobStatus = workFolderInfo.status,          //작업상태(작업/재작업/검수..)
        kpdef = keypointDefinition.Definitions,     //키포인트 위치 고정상수
        isFin = workFolderInfo.isFin,    
        tools = isFin == true ? ["show-tags","pan","create-blurbox","copy","저장","나가기"] : ["pan","zoom","나가기"],
        headApi = process.env.REACT_APP_BASE_URL + "/admin/images/",    //headAPi 주소. 최상위에 .env 파일을 작성하여 지정할 것. 
        //REACT_APP_BASE_URL=http://at-web.vitasoft.co.kr/backend/v1/api
        tokenInfo = annotationService.getHeaders();  //로드 이미지 주소.

  let sourceList = new Array();  
  let rejectedObj = new Object(); // 재작업 시 반려한 목록 가이드

  const [currentPage, setCurrentPage] = useState({
    idx : 0,
    image : {}
  });
  const [inputs, setInputs] = useState({
    isLoading: true,
    totalDataList: [],
    totalLength: 0,
    objectCodeList: [],
    KPObjectCodeList: [],
    tagList: [],
    metaColList: {},
    rejectCodeObj: {},
    rejectDesc: {},
  });

  const {
    isLoading, totalDataList, totalLength, tagList, metaColList, objectCodeList, KPObjectCodeList, rejectCodeObj, rejectDesc
  } = inputs;

  const [labeDesc, setLabeDesc] = useState([]);

  function setClassCodes(classCodes){

    let tags = new Array(),
        rejects = new Object(),
        codes = new Array(),
        kpcodes = new Array(),
        metaz = new Object();

    for(let codeObj of classCodes){
      switch (codeObj.dataType){
          case 'OBJ':
              codes.push(codeObj.className);
              break;
          case 'REJECT':
              rejects[codeObj.classNo] = codeObj.classDescription;
              break;
          case 'TAG':
              tags.push(codeObj.className);
              break;
          case 'SKE17':
            annotationType != "SKE24" && kpcodes.push(codeObj.className);
              break;
          case 'SKE24':
            annotationType == "SKE24" && kpcodes.push(codeObj.className);
              break;
          case 'META':
              metaz[codeObj.className] = codeObj.classDescription.split('|');
              break;
          default:
              break;
      }
    }
    codes = codes.concat(["얼굴","번호판"]);
    const set = new Set(codes);

    const classObj = {
      tagList: tags,
      objectCodeList: [...set],
      KPObjectCodeList: [...kpcodes,"키포인트"],
      rejectCodeObj: jobStatus != '1' ? rejects : {},
      metaColList: metaz
    };

    return classObj;
  } 

  //데이터 파싱
  async function makeResJson(resObj, idx){

    let regi = [];
    let jsonData = resObj.jsonData;
    let keyObj = {};
    let typeCnt = {};
    
    for await(let r of jsonData){
      regi = r.labelData ? regi.concat(r.labelData) : regi;
      const{['labelData'] : labeldatas, ...keyData} = r;
      if(r.labelData && r.labelData.length > 0){typeCnt[r.dataType] = r.labelData.length};
      keyObj[keyData.dataType] = keyData.jsonDataNo;

      if(isFin == false){
        for(let j=0;j<regi.length;j++){
          Object.assign(regi[j], {"isInspect":true});
        }
      }
    }

    //jsondata 이후
    let inspectCode = null;
    let inspectReason = null;
    let metaDescription = resObj.metaDescription ? resObj.metaDescription : {};

    let json = { 
      key : keyObj, 
      src : headApi + resObj.fileNo +'?taskFolderNo=' + workFolderNo,
      name: (idx+1), 
      isViewed: false, 
      regions: regi, 
      preLabelData: [],
      lpPreLabelData: [], 
      typeCnt : typeCnt,
      metaDescription: metaDescription, 
      inspectCode: inspectCode, 
      inspectReason : inspectReason
    };

    return json;

  }

  useEffect(() => {

    annotationService.getAdminFolderImages(workFolderNo,annotationType)
    .then(async (res) => {
        //console.log(res);
        const images = res.images
        const classCodes = res.classCodes;
        const classObj = await setClassCodes(classCodes);
        //우측 표시용 변수 초기화
        let descList = new Array(), //검출 목록 텍스트들의 List
            startIdx = 0, //각 검출 목록의 시작 page
            labelLen = 0; //각 검출 목록의 등장 길이

        let prevTypeCnt = {};
        for await (let [idx, resObj] of images.entries()){
          const json = await makeResJson(resObj, idx);
          sourceList.push(json);
          //검출 목록 생성
          let tempTypeCnt = json.typeCnt;

          //이전 값과 typeCnt에 변화가 발생
          if(JSON.stringify(tempTypeCnt) != JSON.stringify(prevTypeCnt)){
            //이전 값이 빈 값이었음
            if(Object.keys(prevTypeCnt).length === 0){
              startIdx = idx + 1;
              labelLen = 0;
            } else {
              let endIdx = startIdx + labelLen;
              let descText = "";
              for(let [dataType, cnt] of Object.entries(prevTypeCnt)){
                descText = descText + (dataType == "FACE" ? "얼굴" : dataType == "LP" ? "번호판" : "기타") + cnt + "건 "; 
              }
              let ranges = labelLen == 0 ? startIdx.toString() : startIdx.toString() + " ~ " + endIdx.toString();
              let text = {name: descText, ranges:ranges, startIdx:startIdx, endIdx:endIdx, cnt: endIdx-startIdx+1};     
              descList.push(text);
              if (Object.keys(tempTypeCnt).length !== 0){
                //0개 초과에서 0개 아닌 다른 개수로 변화함.
                startIdx = idx + 1;
              }
            }
            //변화로 인한 초기화
            prevTypeCnt = tempTypeCnt;
            labelLen = 0;
          } else {
            //라벨 개수 유지 시
            labelLen = labelLen + 1;
          }
          //마지막 페이지인 경우이며 prevTypeCnt에 뭔가 있음.
          if((idx + 1) == images.length && Object.keys(prevTypeCnt).length !== 0){
            let endIdx = startIdx + labelLen;
            let descText = "";
            for(let [dataType, cnt] of Object.entries(prevTypeCnt)){
              descText = descText + (dataType == "FACE" ? "얼굴" : dataType == "LP" ? "번호판" : "기타") + cnt + "건 "; 
            }
            let ranges = labelLen == 0 ? startIdx.toString() : startIdx.toString() + " ~ " + endIdx.toString();
            let text = {name: descText, ranges:ranges, startIdx:startIdx, endIdx:endIdx, cnt: endIdx-startIdx+1};     
            descList.push(text);
          }
        }

        //결과 없으면 일단 내보내자.
        if(sourceList.length == 0){
          alert("이미지 로드 중 오류가 발생했습니다.")
          history.go(-1)
          return false;
        }

        localStorage.setItem("resetLabelFlag",true);
        let loadImage = makeImmutable.asMutable(sourceList[0])
        if(!loadImage.blobUrl){
          await axios.get(loadImage.src, { headers: tokenInfo, responseType: 'arraybuffer'}).then((res) => {
            let blob = new Blob([res.data], { type: "image/png" });
            const url = window.URL.createObjectURL(blob);
            loadImage.blobUrl = url;
          });
        }

        setCurrentPage({
          idx : 0,
          image : [loadImage]
        });
        setLabeDesc(descList);
        setInputs({
          ...inputs,
          ...classObj,
          totalDataList: sourceList,
          totalLength: sourceList.length,
          rejectDesc: rejectedObj,
          isLoading: false,
        })
       
    })
    .catch(error => {
        console.error(error);
    });


  },[annotationService]);

  const AnnotationTool = ReactImageAnnotate
  return (
    <>
    {isLoading ? 
      <div>
        <div style={{display:"flex", justifyContent: "space-between"}}>
          <Skeleton variant="text" width={300} height={50} style={{marginLeft:"20px"}}/>
          <Skeleton variant="rect" width={160} height={38} style={{marginRight:"140px", marginTop:"5px"}}/>
          <Skeleton variant="rect" width={200} height={38} style={{marginTop:"5px"}}/>
        </div>
        <div style={{display:"flex"}}>
          <Skeleton variant="rect" width={20} height={650} style={{marginLeft:"15px"}}/>
          <Skeleton variant="rect" width={1350} height={780} style={{marginLeft:"15px"}} />
          <Skeleton variant="rect" width={400} height={780} style={{marginLeft:"5px"}}/>
        </div>
      </div>
      : 
      <>
    <AnnotationTool
      workType={isFin == true ? "adminFin": "adminPrg"}
      labelImages
      enabledTools={tools}
      regionClsList={objectCodeList}
      kpClsList={KPObjectCodeList}
      regionTagList={tagList}
      keypointDefinitions={kpdef}
      metaDataColumn = {metaColList}
      labelDescription={labeDesc}
      rejectedDescription={rejectDesc}
      rejectCodeObj={rejectCodeObj}
      images={currentPage.image}
      totalLength={totalLength}
      selectedImage={0}
      jobInfo={workFolderInfo}
      tokenInfo={tokenInfo}
      currentIdx={currentPage.idx}
      onPageChange={async (prevPageData, prevPageIdx, nextPageIdx) =>{
        //console.log(prevPageData);
        let tempData = makeImmutable.asMutable(totalDataList);
        let nextData = makeImmutable.asMutable(tempData[nextPageIdx]);
        if(!nextData.blobUrl){
          await axios.get(nextData.src, { headers: tokenInfo, responseType: 'arraybuffer'}).then((res) => {
            let blob = new Blob([res.data], { type: "image/png" });
            const url = window.URL.createObjectURL(blob);
            nextData.blobUrl = url;
          });
        }
        setCurrentPage({
          idx : nextPageIdx,
          image : [nextData]
        });
        tempData.splice(prevPageIdx,1,prevPageData);
        setInputs({
          ...inputs,
          totalDataList: tempData
        })
      }}
      onExit={(args) =>{
          history.go(-1);
      }}
      onSave={(args) => {
        //저장(제출) 버튼 클릭 시 json 데이터 제출
        //console.log(args);

        //분리한 데이터 재통합
        let tempData = makeImmutable.asMutable(totalDataList);
        tempData.splice(currentPage.idx,1,args.images[0]);

        //전송 리스트 선언
        let jsonList = new Array();
        let reqList = new Array();

        //전송 데이터 파싱 (FL 과 FL 아닌 경우로 구분)
        if(annotationType == "FL"){
          let lpJsonList = new Array();
          for(let data of tempData){
            if(!data.isViewed) continue;
            let labelData = data.regions == undefined ? [] : data.regions;
            let faceLabelData = [];
            let lpLabelData = [];
            labelData.forEach((element, idx)=>{
              element.cls === "번호판" ? lpLabelData.push(element) : faceLabelData.push(element);
            })
            var json = { jsonDataNo : data.key["FACE"] , labelData : faceLabelData , metaDescription : (data.metaDescription ? data.metaDescription : {}) ,  labelCnt : faceLabelData.length };
            var lpJson = { 
              jsonDataNo : data.key["LP"], 
              labelData : lpLabelData, 
              metaDescription : (data.metaDescription ? data.metaDescription : {}),
              labelCnt : lpLabelData.length 
            };
            //검수 시 한정
            jsonList.push(json);
            lpJsonList.push(lpJson);
          }
          reqList.push(lpJsonList);
        } else {
          for(let data of tempData){
            //미열람 데이터는 저장 안함
            if(!data.isViewed) continue;
            let labelData = data.regions == undefined ? [] : data.regions;
            let json = { 
              jsonDataNo : data.key[annotationType], 
              labelData : labelData, 
              metaDescription : (data.metaDescription ? data.metaDescription : {}),
              labelCnt : labelData.length 
            };
            jsonList.push(json);
          }
        }
        reqList.push(jsonList);
        //console.log("====보내는값:",workFolderNo)
        //console.log(reqList);

        //작업 전송
        if(isFin){
          annotationService.postAdminJson(reqList,workFolderNo)
          .then(function(res){
            notification['success']({
              message: '작업 정보 저장 완료',
            });
          })
          .catch(error => {
            console.error(error);
            notification['error']({
              message: '저장에 실패하였습니다.',
            });
          });
        }
      }}
    />
    </>
  }
  </>
  );
});

export default AnnotationVideoAdmin;
