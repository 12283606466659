import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  ConfigProvider,
  Descriptions,
  List,
  Input,
  Comment,
  Tooltip,
  PageHeader,
} from "antd";
import { useParams, useHistory } from "react-router-dom";
import ContentEditable from "react-contenteditable";
import { DownloadOutlined, DeleteOutlined } from "@ant-design/icons";
import Loading from "../../common/Loading";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

const { TextArea } = Input;

const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <>
    <Form.Item>
      <TextArea rows={3} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Button
        htmlType="submit"
        loading={submitting}
        onClick={onSubmit}
        type="primary"
      >
        답글 달기
      </Button>
    </Form.Item>
  </>
);

/**
 * 묻고 답하기
 * @returns {JSX.Element}
 * @constructor
 */
const QnaDetail = ({ articleService, projectInfo }) => {
  const [qnaView, setQnaView] = useState([]);
  const [commentList, setCommentList] = useState([]);
  const [contentEditable] = useState(React.createRef());
  const [isLoading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  let projectNo = projectInfo.projectNo;
  const { questionId } = useParams();
  const history = useHistory();

  const customizeRenderEmpty = () => (
    <div style={{ textAlign: "center" }}>
      <p>답변이 없습니다.</p>
    </div>
  );

  //답글 추가 관련
  const [submitting, setSubmitting] = useState(false);
  const [commentContent, setCommentContent] = useState(null);

  const handleChange = (e) => {
    setCommentContent(e.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onQuestionRemove = (value) => {
    if (value == "qustion") {
      articleService.deleteQuestion(projectNo, questionId).then((res) => {
        history.push("/qnaList");
      });
    } else {
      articleService.deleteAnswer(projectNo, questionId, value).then((res) => {
        articleService.getQnaView(projectNo, questionId).then((r) => {
          setCommentList(r.answers);
        });
      });
    }
  };

  const handleSubmit = () => {
    if (!commentContent) {
      return;
    }
    var comment = { questionId: questionId, answer: commentContent };
    articleService.postAnswer(projectNo, comment).then((res) => {
      articleService.getQnaView(projectNo, questionId).then((r) => {
        setCommentList(r.answers);
        setCommentContent(null);
      });
    });
  };

  useEffect(() => {
    articleService.getQnaView(projectNo, questionId).then((r) => {
      console.log(r);
      setQnaView(r);
      setCommentList(r.answers);
      setLoading(false);
    });
  }, [articleService]);

  const onBtnList = (command) => {
    switch (command) {
      case "list": {
        history.push("/qnaList");
        break;
      }
      case "del": {
        handleClickOpen();
        break;
      }
      case "edit": {
        history.push(`/qnaEdit/${questionId}`);
        break;
      }
    }
  };

  const downloadFile = (file, displayName) => {
    let blob = new Blob([file], {
      type: "application/octet-stream",
    });
    let name = displayName; //파일명 디코딩
    let fileName = getFileName(name);
    if (window.navigator.msSaveOrOpenBlob) {
      // IE 10+는 해당 함수 제공
      window.navigator.msSaveOrOpenBlob(blob, name);
    } else {
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.target = "_self";
      if (name) link.download = name;
      link.click();
      window.URL.revokeObjectURL(link); //메모리 누수 방지
    }
  };

  const getFileName = (contentDisposition) => {
    let fileName = contentDisposition
      .split(";")
      .filter((el) => el.indexOf("filename") > -1)
      .map((ele) => ele.replace(/"/g, "").split("=")[1]);
    return fileName[0];
  };

  const onFileView = (articleFileId, displayName) => {
    articleService
      .getQnaFileView(projectNo, questionId, articleFileId)
      .then((r) => {
        downloadFile(r, displayName);
      });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <style jsx="true">{`
        .ant-descriptions-item-label {
          width: 160px !important;
        }
      `}</style>

      <PageHeader
        className="site-page-header"
        title="묻고 답하기"
        extra={[
          qnaView.owner ? (
            <Button type="primary" onClick={() => onBtnList("edit")}>
              수정
            </Button>
          ) : (
            ""
          ),
          qnaView.owner ? (
            <Button danger onClick={() => onBtnList("del")}>
              삭제
            </Button>
          ) : (
            ""
          ),
          <Button onClick={() => onBtnList("list")}>목록</Button>,
        ]}
      />
      <Descriptions bordered>
        <Descriptions.Item label="제목" span={3}>
          {qnaView.title}
        </Descriptions.Item>
        <Descriptions.Item id="content" label="내용" span={3}>
          <ContentEditable
            tagName="article"
            innerRef={contentEditable}
            html={qnaView.content}
            disabled={true}
          />
        </Descriptions.Item>
        <Descriptions.Item id="files" label="첨부파일" span={3}>
          {qnaView.files
            ? qnaView.files.map((f) => (
                <Button
                  type="link"
                  icon={<DownloadOutlined />}
                  onClick={() => onFileView(f.articleFileId, f.displayName)}
                >
                  {f.displayName}
                </Button>
              ))
            : ""}
        </Descriptions.Item>
      </Descriptions>
      <br />
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <List
          className="comment-list"
          header={`${commentList.length} 개의 답변`}
          itemLayout="horizontal"
          dataSource={commentList}
          renderItem={(item) => (
            <li>
              <Comment
                author={item.regId}
                content={item.answer}
                actions={
                  item.owner
                    ? [
                        <Tooltip title="답변 삭제">
                          <span onClick={() => onQuestionRemove(item.answerId)}>
                            {React.createElement(DeleteOutlined)}
                          </span>
                        </Tooltip>,
                      ]
                    : []
                }
                datetime={item.regDate.replace("T", " ")}
              />
            </li>
          )}
        />
      </ConfigProvider>
      <Comment
        content={
          <Editor
            onChange={handleChange}
            onSubmit={handleSubmit}
            submitting={submitting}
            value={commentContent}
          />
        }
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`알림`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            삭제 하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            취소
          </Button>
          <Button
            onClick={() => onQuestionRemove("qustion")}
            color="primary"
            autoFocus
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default QnaDetail;
