import React, { useEffect, useState } from "react";
import { Button, message, PageHeader, Table, Tag } from "antd";
import { useHistory } from "react-router-dom";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { notification } from "antd";

/**
 * 내 진행 중 작업
 * @returns {JSX.Element}
 * @constructor
 */
const MyJobList = ({ projectService, projectInfo, getWorkFolderInfo }) => {
  const [myJobList, setMyJobList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [taskValue, setTaskValue] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  let projectNo = projectInfo.projectNo;

  useEffect(() => {
    projectService
      .getMyJobList(projectNo)
      .then((res) => {
        console.log(res);
        setMyJobList(res);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [projectService]);

  const handleClickOpen = (value) => {
    setOpen(true);
    setTaskValue(value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onJobRestart = (value, isRemain) => {
    handleClose();
    getWorkFolderInfo(value);

    if (value.fileType == "VIDEO") {
      history.push("/annotationVideoJob");
    } else if (isRemain) {
      history.push("/annotationImage/remain");
    } else {
      history.push("/annotationImage/all");
    }
  };

  //작업 완료

  const onJobComplete = (taskFolderNo, role) => {
    handleClose();
    projectService
      .onJobEnd(projectNo, taskFolderNo, role)
      .then((r) => {
        message.info("작업 완료 처리되었습니다.");
        projectService
          .getMyJobList(projectNo)
          .then((res) => {
            console.log(res);
            setMyJobList(res);
            setLoading(false);
          })
          .catch((error) => {
            console.error(error);
            notification["error"]({
              message: "작업 완료 실패",
            });
          });
      })
      .catch((error) => {
        console.error(error);
        notification["error"]({
          message:
            error.response.status === 409
              ? "채택 10개일때 작업 완료 가능합니다. 작업을 확인하세요."
              : "네트워크 에러. 관리자에게 문의하세요.",
        });
      });
  };

  const columns = [
    // {
    //   title: "No",
    //   dataIndex: "rowNum",
    //   key: "rowNum",
    //   width: "5%",
    //   align: "center",
    //   sorter: (a, b) => b.rowNum - a.rowNum,
    // },

    {
      title: "태스크",
      dataIndex: "taskName",
      key: "taskName",
      width: "10%",
    },
    {
      title: "폴더",
      width: "30%",
      render: (value) => {
        return <>{value.folderPath}</>;
      },
    },

    // {
    //   title: "AI 선가공 유형",
    //   dataIndex: "dataType",
    //   key: "dataType",
    //   width: "10%",
    //   render: (value) => {
    //     let tag = "오류";
    //     let color = "red";
    //     switch (value) {
    //       case "FACE":
    //         tag = "비식별화(얼굴)";
    //         color = "geekblue";
    //         break;
    //       case "OBJ":
    //         tag = "객체인식";
    //         color = "green";
    //         break;
    //       case "SKE17":
    //         tag = "스켈레톤17";
    //         color = "purple";
    //         break;
    //       case "SKE24":
    //         tag = "스켈레톤24";
    //         color = "purple";
    //         break;
    //       case "OCR":
    //         tag = "OCR";
    //         color = "orange";
    //         break;
    //       case "LP":
    //         tag = "비식별화(번호판)";
    //         color = "orange";
    //         break;
    //       case "FL":
    //         tag = "비식별화";
    //         color = "orange";
    //         break;
    //       case "CAPTIONING":
    //         tag = "캡셔닝";
    //         color = "green";
    //         break;
    //       default:
    //         tag = "선가공 없음";
    //         color = "darkgray";
    //         break;
    //     }
    //     return (
    //       <Tag color={color} style={{ fontSize: "14px" }}>
    //         {tag}
    //       </Tag>
    //     );
    //   },
    // },

    // {
    //   title: "파일유형",
    //   dataIndex: "fileType",
    //   key: "fileType",
    //   width: "8%",
    //   render: (value) => {
    //     return (
    //       <Tag color={"cyan"} style={{ fontSize: "14px" }}>
    //         {value}
    //       </Tag>
    //     );
    //   },
    // },
    {
      title: "상태",
      dataIndex: "status",
      key: "status",
      width: "9%",
      render: (value) => {
        let color = "red";
        let stat = null;
        switch (value) {
          case "1":
            stat = "작업 중";
            color = "geekblue";
            break;
          case "2":
            stat = "작업 완료";
            color = "green";
            break;
          case "3":
            stat = "검수 중";
            color = "purple";
            break;
          case "4":
            stat = "검수 완료";
            color = "green";
            break;
          case "5":
            stat = "재작업 요청";
            color = "orange";
            break;
          case "6":
            stat = "재검수 대기";
            color = "cyan";
            break;
          default:
            stat = "error";
            color = "red";
        }
        return (
          <Tag color={color} style={{ fontSize: "14px" }}>
            {stat}
          </Tag>
        );
      },
    },
    {
      title: "전체 파일 수",
      dataIndex: "fileCount",
      key: "fileCount",
      width: "10%",
    },
    {
      title: "남은 이미지 수",
      dataIndex: "remainingCount",
      key: "remainingCount",
      width: "10%",
      render: (value, record) => {
        if (record.status == "5") {
          return <>{record.rejectedCount}</>;
        } else if (record.fileType == "VIDEO") {
          return <>-</>;
        } else {
          return <>{value}</>;
        }
      },
    },
    {
      title: "관리",
      key: "taskFolderNo",
      width: "4%",
      render: (value, record) => {
        return (
          <>
            {record.status == "5" ? (
              <>
                <div>
                  <Button
                    style={{ marginBottom: "6px" }}
                    onClick={() => onJobRestart(value, false)}
                  >
                    재작업 실행
                  </Button>
                </div>
                <div>
                  <Button type="primary" onClick={() => handleClickOpen(value)}>
                    재검수 요청
                  </Button>
                </div>
              </>
            ) : (
              ""
            )}

            {record.status == "1" ? (
              <>
                <div>
                  <Button
                    style={{ marginBottom: "6px" }}
                    onClick={() => onJobRestart(value, false)}
                  >
                    작업 계속
                  </Button>
                </div>
                <div>
                  {record.remainingCount > 0 ? (
                    <Button
                      type="danger"
                      onClick={() => onJobRestart(value, true)}
                    >
                      미열람 작업
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  {record.remainingCount === 0 ? (
                    <Button
                      type="primary"
                      onClick={() => handleClickOpen(value)}
                    >
                      작업 완료
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : (
              ""
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      {/*내 진행 중 작업 : USER_ID 가 자신이며 완료되지 않은 TASK들을 리스트로 출력하는 페이지.
            입력값 : project_no
            출력값 :
            TASK_NO(정렬기준) ,  task_name, type, 진행된 개수, 전체 파일개수(task_file 테이블에서), REG_DATE*/}

      <PageHeader className="site-page-header" title="내 진행중인 작업" />
      <Table
        columns={columns}
        dataSource={myJobList}
        rowKey={(record) => record.taskFolderNo}
        loading={isLoading}
        showSorterTooltip={false}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`알림`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            작업 완료 시 더 이상 수정작업이 불가합니다. <br />
            {taskValue.taskName} - {taskValue.folderPath} <br />
            작업 완료를 진행 하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            취소
          </Button>
          <Button
            onClick={() =>
              onJobComplete(taskValue.taskFolderNo, taskValue.role)
            }
            color="primary"
            autoFocus
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MyJobList;
