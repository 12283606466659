import React, { useEffect, useState } from "react";
import { message, PageHeader, Table, Tag, Button } from "antd";
import { useHistory } from "react-router-dom";
import moment from "moment";
import "moment/locale/ko";
/**
 * 정산 관리
 * @returns {JSX.Element}
 * @constructor
 */
const AccountList = ({ projectService, projectInfo }) => {
  const [isLoading, setLoading] = useState(true);
  const [accountList, setAccountList] = useState([]);
  const history = useHistory();

  let projectNo = projectInfo.projectNo;

  useEffect(() => {
    projectService.getAccountList(projectNo).then((res) => {
      console.log(res);
      setAccountList(res);
      setLoading(false);
    });
  }, [projectService]);

  const columns = [
    // {
    //   title: "No",
    //   dataIndex: "rowNum",
    //   key: "rowNum",
    //   width: "5%",
    //   align: "center",
    // },
    {
      title: "태스크",
      dataIndex: "taskName",
      key: "taskName",
      width: "7.3%",
    },
    // {
    //   title: "데이터명",
    //   dataIndex: "dataTitle",
    //   key: "dataTitle",
    //   width: "12%",
    // },
    // {
    //     title: 'AI 선가공 유형',
    //     dataIndex: 'fileType',
    //     key: 'fileType',
    //     width: "7%",
    //     render: (value) => {
    //         return (
    //             <Tag color={"cyan"} key={value} style={{ fontSize: '14px'}}>
    //                 {value}
    //             </Tag>
    //         );
    //     }
    // },
    {
      title: "작업일",
      dataIndex: "workDate",
      key: "workDate",
      width: "8%",
      render: (value) => moment(value).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "계산방식",
      dataIndex: "unitType",
      key: "unitType",
      width: "6%",
      render: (value) => {
        if (value == "LABEL") {
          return (
            <Tag color={"purple"} key={value} style={{ fontSize: "14px" }}>
              라벨
            </Tag>
          );
        } else {
          return (
            <Tag color={"orange"} key={value} style={{ fontSize: "14px" }}>
              이미지
            </Tag>
          );
        }
      },
    },
    {
      title: "작업 단가",
      dataIndex: "unitPrice",
      key: "unitPrice",
      width: "7%",
      render: (value) => {
        const payPrice = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return <>{payPrice} 원</>;
      },
    },
    {
      title: "작업량",
      width: "7%",
      render: (value) => {
        if (value.unitType == "LABEL") {
          return <>{value.labelCnt}</>;
        } else {
          return <>{value.fileCnt}</>;
        }
      },
    },
    {
      title: "정산 가능 금액",
      dataIndex: "payment",
      key: "payment",
      width: "8%",
      render: (value) => {
        const pay = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return <>{pay} 원</>;
      },
    },
    {
      title: "정산일",
      dataIndex: "accountStatus",
      key: "accountStatus",
      width: "10%",
      render: (value, record) => {
        if (value == "0") {
          return (
            <Tag color={"red"} key={value} style={{ fontSize: "14px" }}>
              정산전
            </Tag>
          );
        } else {
          return <>{moment(record.accountDate).format("YYYY-MM-DD HH:mm")}</>;
        }
      },
    },
  ];

  return (
    <>
      <PageHeader className="site-page-header" title="정산 관리" />
      <Table
        columns={columns}
        dataSource={accountList}
        rowKey={(record) => record.accountNo}
        loading={isLoading}
      />
    </>
  );
};

export default AccountList;
