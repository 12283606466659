import React, { useEffect, useState } from "react";
import { PageHeader, Table, Tag, Button, message } from "antd";
import { useHistory } from "react-router-dom";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

/**
 * 내 진행 중 검수
 * @returns {JSX.Element}
 * @constructor
 */
const InspcProgressList = ({
  projectService,
  projectInfo,
  getWorkFolderInfo,
}) => {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [taskValue, setTaskValue] = React.useState([]);
  const [isLoading, setLoading] = useState(true);
  const [myProgressInspection, setMyProgressInspection] = useState([]);

  let projectNo = projectInfo.projectNo;

  useEffect(() => {
    projectService
      .getMyProgressInspection(projectNo)
      .then((r) => {
        console.log(r);
        setMyProgressInspection(r);
        setLoading(false);
      })
      .catch((error) => {
        message.error(error.message);
      });
  }, [projectService]);

  const handleClickOpen = (value) => {
    setTaskValue(value);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //검수 재개
  const onInspectRestart = (value, isRemain) => {
    handleClose();
    getWorkFolderInfo(value);

    if (value.fileType == "VIDEO") {
      history.push("/annotationVideoInspection");
    } else if (isRemain) {
      history.push("/annotationImage/remain");
    } else {
      history.push("/annotationImage/all");
    }
  };

  //검수 완료
  const onInspectComplete = (taskFolderNo, role) => {
    handleClose();
    projectService
      .onJobEnd(projectNo, taskFolderNo, role)
      .then((r) => {
        message.info("검수 완료 처리되었습니다.");
        projectService
          .getMyProgressInspection(projectNo)
          .then((res) => {
            console.log(res);
            setMyProgressInspection(res);
            setLoading(false);
          })
          .catch((error) => {
            message.error(error.message);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const columns = [
    // {
    //   title: "No",
    //   dataIndex: "rowNum",
    //   key: "rowNum",
    //   width: "5%",
    //   align: "center",
    // },

    {
      title: "태스크",
      dataIndex: "taskName",
      key: "taskName",
      width: "16.8%",
    },
    {
      title: "폴더",
      width: "30%",
      render: (value) => {
        return <>{value.folderPath}</>;
      },
    },
    {
      title: "상태",
      dataIndex: "status",
      key: "status",
      width: "9%",
      render: (value) => {
        let color = "red";
        let stat = null;
        switch (value) {
          case "1":
            stat = "작업 중";
            color = "geekblue";
            break;
          case "2":
            stat = "작업 완료";
            color = "green";
            break;
          case "3":
            stat = "검수 중";
            color = "purple";
            break;
          case "4":
            stat = "검수 완료";
            color = "green";
            break;
          case "5":
            stat = "재작업 요청";
            color = "orange";
            break;
          case "6":
            stat = "재검수 대기";
            color = "cyan";
            break;
          default:
            stat = "error";
            color = "red";
        }
        return (
          <Tag color={color} style={{ fontSize: "14px" }}>
            {stat}
          </Tag>
        );
      },
    },
    {
      title: "작업자",
      dataIndex: "workId",
      key: "workId",
      width: "8%",
    },
    // {
    //     title: 'AI 선가공 유형',
    //     dataIndex: 'dataType',
    //     key: 'dataType',
    //     width: "8%",
    //     render: (value) => {
    //         let tag = "오류";
    //         let color = "red";
    //         switch (value) {
    //             case "FACE":
    //                 tag = "비식별화(얼굴)";
    //                 color = "geekblue";
    //                 break;
    //             case "OBJ":
    //                 tag = "객체인식";
    //                 color = "green";
    //                 break;
    //             case "SKE17":
    //                 tag = "스켈레톤17";
    //                 color = "purple";
    //                 break;
    //             case "SKE24":
    //                 tag = "스켈레톤24";
    //                 color = "purple";
    //                 break;
    //             case "OCR":
    //                 tag = "OCR";
    //                 color = "orange";
    //                 break;
    //             case "LP":
    //                 tag = "비식별화(번호판)";
    //                 color = "orange";
    //                 break;
    //             case "FL":
    //                 tag = "비식별화";
    //                 color = "orange";
    //                 break;
    //             case "CAPTIONING":
    //                 tag = "캡셔닝";
    //                 color = "green"
    //                 break;
    //             default:
    //                 tag = "선가공 없음";
    //                 color = "darkgray";
    //                 break;
    //         }
    //         return (
    //             <Tag color={color} style={{ fontSize: '14px'}}>
    //                 {tag}
    //             </Tag>
    //         );
    //     }
    // },
    // {
    //     title: '파일유형',
    //     dataIndex: 'fileType',
    //     key: 'fileType',
    //     width: "8%",
    //     render: (value) => {
    //         return (
    //             <Tag color={"cyan"} key={value} style={{ fontSize: '14px'}}>
    //                 {value}
    //             </Tag>
    //         );
    //     }
    // },

    {
      title: "전체 파일 수",
      dataIndex: "fileCount",
      key: "fileCount",
      width: "7%",
    },
    {
      title: "남은 이미지 수",
      dataIndex: "inspectionRemainingCount",
      key: "inspectionRemainingCount",
      width: "7%",
      render: (value, record) => {
        if (record.status == "6") {
          return <>{value}</>;
        } else if (record.fileType == "VIDEO") {
          return <>-</>;
        } else {
          return <>{value}</>;
        }
      },
    },
    {
      title: "관리",
      width: "4%",
      render: (value, record) => {
        return (
          <>
            {record.status == "6" ? (
              <>
                <div>
                  <Button
                    style={{ marginBottom: "6px" }}
                    onClick={() => onInspectRestart(value, false)}
                  >
                    재검수 실행
                  </Button>
                </div>
                <div>
                  {record.inspectionRemainingCount > 0 ? (
                    <Button
                      type="danger"
                      onClick={() => onInspectRestart(value, true)}
                    >
                      미열람 검수
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      onClick={() => handleClickOpen(value)}
                    >
                      재검수 완료
                    </Button>
                  )}
                </div>
              </>
            ) : (
              ""
            )}
            {record.status == "3" ? (
              <>
                <div>
                  <Button
                    style={{ marginBottom: "6px" }}
                    onClick={() => onInspectRestart(value, false)}
                  >
                    검수 계속
                  </Button>
                </div>
                <div>
                  {record.inspectionRemainingCount > 0 ? (
                    <Button
                      type="danger"
                      onClick={() => onInspectRestart(value, true)}
                    >
                      미열람 검수
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      onClick={() => handleClickOpen(value)}
                    >
                      검수 완료
                    </Button>
                  )}
                </div>
                {/* <div>
                  {record.inspectionRemainingCount === 0 ? (
                    <Button
                      type="primary"
                      onClick={() => handleClickOpen(value)}
                    >
                      검수 완료
                    </Button>
                  ) : (
                    ""
                  )}
                </div> */}
              </>
            ) : (
              ""
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      {/*내 진행 중 검수 : 자신이 inspector인 1,2차검수중 TASK들을 리스트로 출력하는 페이지.
            입력값 : project_no
            출력값 :
            TASK_NO(정렬기준) ,  task_name, type, 파일개수(task_file 테이블에서), REG_DATE*/}
      <PageHeader className="site-page-header" title="내 진행 중 검수" />
      <Table
        columns={columns}
        dataSource={myProgressInspection}
        rowKey={(record) => record.folderNo}
        loading={isLoading}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`알림`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            검수 완료 시 더 이상 수정작업이 불가합니다. <br />
            {taskValue.taskName} - {taskValue.folderPath} <br />
            검수를 완료 하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            취소
          </Button>
          <Button
            onClick={() =>
              onInspectComplete(taskValue.taskFolderNo, taskValue.role)
            }
            color="primary"
            autoFocus
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InspcProgressList;
