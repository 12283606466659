import axios from 'axios';
const qs = require('querystring');
 
export default class PapagoService{

constructor(config) {
    this.config = config;
}

async lookup(term, { method }) {

    const TRANSLATE_METHODS = {
        nmt: 'nmt',
        smt: 'smt',
        };
        
    if (this.config == null) {
        throw new Error('Papago instance should be initialized with config first');
    } if (term == null) {
        throw new Error('Search term should be provided as lookup arguments');
    }

    const url = method === TRANSLATE_METHODS.smt ?
        '/language/translate' : '';

    const params = qs.stringify({
        source: 'ko',
        target: 'en',
        text: term,
    });

    const config = {
        baseURL: "/nmt/v1/translation",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'X-NCP-APIGW-API-KEY-ID': this.config.NAVER_CLIENT_ID,
            'X-NCP-APIGW-API-KEY': this.config.NAVER_CLIENT_SECRET,
            // 'access-control-allow-origin': '*',
            // 'access-control-allow-method': "OPTION, POST"
        },
    };

    const response = await axios.post(url, params, config);

    // console.log(response)
    return response.data.message.result.translatedText;
}
}


