export default class ArticleService {
    constructor(http, tokenStorage) {
        this.http = http;
        this.tokenStorage = tokenStorage;
    }

    /**
     * 프로젝트 공지사항 리스트 조회
     */
    async getNoticeList(projectNo) {
        return this.http.axios(`/articles?projectNo=${projectNo}`, {
            method: 'GET',
            headers: this.getHeaders(),
        });
    }

    async getNoticeView(articleId,projectNo) {
        return this.http.axios(`/articles/${articleId}?projectNo=${projectNo}`, {
            method: 'GET',
            headers: this.getHeaders(),
        });
    }

     /**
     * 공지사항 파일 보기
     */
      async getNoticeFileView(projectNo,articleId,articleFileId) {
        return this.http.axios(`/articles/${articleId}/download?articleFileId=${articleFileId}&projectNo=${projectNo}`, {
            method: 'GET',
            responseType: 'arraybuffer',
            headers: this.getFileHeaders(),
        });
    }

    /**
     * QNA 리스트 조회
     */
     async getQnaList(projectNo) {
        return this.http.axios(`/questions?projectNo=${projectNo}`, {
            method: 'GET',
            headers: this.getHeaders(),
        });
    }

    /**
     * QNA 질문 등록
     */
    async postQuestion(projectNo,formData) {
        return this.http.axios2(`/questions?projectNo=${projectNo}`, {
            method: 'POST',
            data: formData,
            headers: this.getFileHeaders(),
        });
    }

    /**
     * QNA 질문 수정
     */
    async patchQuestion(projectNo,content) {
        return this.http.axios(`/questions/${content.questionId}?projectNo=${projectNo}`, {
            method: 'PATCH',
            data: content,
            headers: this.getHeaders(),
        });
    }

    /**
     * QNA 질문 삭제
     */
    async deleteQuestion(projectNo,questionId) {
        return this.http.axios(`/questions/${questionId}?projectNo=${projectNo}`, {
            method: 'DELETE',
            headers: this.getHeaders(),
        });
    }

    /**
     * QNA 답변 등록
     */
     async postAnswer(projectNo,comment) {
        return this.http.axios(`/questions/${comment.questionId}/answers?projectNo=${projectNo}`, {
            method: 'POST',
            data: comment,
            headers: this.getHeaders(),
        });
    }

    /**
     * QNA 답변 수정
     */
     async patchAnswer(projectNo,comment) {
        return this.http.axios(`/questions/${comment.questionId}/answers/${comment.answerId}?projectNo=${projectNo}`, {
            method: 'PATCH',
            data: comment,
            headers: this.getHeaders(),
        });
    }

    /**
     * QNA 답변 삭제
     */
     async deleteAnswer(projectNo,questionId,answerId) {
        return this.http.axios(`/questions/${questionId}/answers/${answerId}?projectNo=${projectNo}`, {
            method: 'DELETE',
            headers: this.getHeaders(),
        });
    }
    
    
    /**
     * QNA 내용 보기
     */
    async getQnaView(projectNo,questionId) {
        return this.http.axios(`/questions/${questionId}?projectNo=${projectNo}`, {
            method: 'GET',
            headers: this.getHeaders(),
        });
    }

    /**
     * QNA 파일 보기
     */
    async getQnaFileView(projectNo,questionId,articleFileId) {
        return this.http.axios(`/questions/${questionId}/download?articleFileId=${articleFileId}&projectNo=${projectNo}`, {
            method: 'GET',
            responseType: 'arraybuffer',
            headers: this.getFileHeaders(),
        });
    }

    getHeaders() {
        const token = this.tokenStorage.getToken();
        return {
            Authorization: `Bearer ${token}`,
        };
    }

    getFileHeaders() {
        const token = this.tokenStorage.getToken();
        return {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        };
    }

}
