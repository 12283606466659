import React, { useEffect, useState } from "react";
import { message, PageHeader, Table, Tag, Button } from "antd";
import { useHistory } from "react-router-dom";

/**
 * 내 완료된 작업
 * @returns {JSX.Element}
 * @constructor
 */
const JobAdmin = ({ getWorkFolderInfo, projectService, projectInfo }) => {
  const [myJobAdmin, setMyJobAdmin] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const history = useHistory();

  let projectNo = projectInfo.projectNo;

  useEffect(() => {
    projectService.getJobAdmin(projectNo, "PROCESSING").then((r) => {
      console.log(r);
      setMyJobAdmin(r.taskFolders);
      setLoading(false);
    });
  }, [projectService]);

  const onJobRestart = (value) => {
    getWorkFolderInfo({
      ...value,
      isFin: false,
    });

    if (value.fileType == "VIDEO") {
      history.push("/annotationVideoAdmin");
    } else {
      history.push("/annotationImageAdmin");
    }
  };

  const columns = [
    // {
    //   title: "No",
    //   dataIndex: "rowNum",
    //   key: "rowNum",
    //   width: "5%",
    //   align: "center",
    // },

    {
      title: "태스크",
      dataIndex: "taskName",
      key: "taskName",
      width: "17.5%",
    },
    {
      title: "폴더",
      width: "30%",
      render: (value) => {
        return <>{value.folderPath}</>;
      },
    },

    // {
    //     title: 'AI 선가공 유형',
    //     dataIndex: 'dataType',
    //     key: 'dataType',
    //     width: "7%",
    //     render: (value) => {
    //         let tag = "오류";
    //         let color = "red";
    //         switch (value) {
    //             case "FACE":
    //                 tag = "비식별화(얼굴)";
    //                 color = "geekblue";
    //                 break;
    //             case "OBJ":
    //                 tag = "객체인식";
    //                 color = "green";
    //                 break;
    //             case "SKE17":
    //                 tag = "스켈레톤17";
    //                 color = "purple";
    //                 break;
    //             case "SKE24":
    //                 tag = "스켈레톤24";
    //                 color = "purple";
    //                 break;
    //             case "OCR":
    //                 tag = "OCR";
    //                 color = "orange";
    //                 break;
    //             case "LP":
    //                 tag = "비식별화(번호판)";
    //                 color = "orange";
    //                 break;
    //             case "FL":
    //                 tag = "비식별화";
    //                 color = "orange";
    //                 break;
    //             case "CAPTIONING":
    //                 tag = "캡셔닝";
    //                 color = "green"
    //                 break;
    //             default:
    //                 tag = "선가공 없음";
    //                 color = "darkgray";
    //                 break;
    //         }
    //         return (
    //             <Tag color={color} style={{ fontSize: '14px'}}>
    //                 {tag}
    //             </Tag>
    //         );
    //     }
    // },

    // {
    //     title: '파일유형',
    //     dataIndex: 'fileType',
    //     key: 'fileType',
    //     width: "7%",
    //     render: (value) => {
    //         return (
    //             <Tag color={"cyan"} key={value} style={{ fontSize: '14px'}}>
    //                 {value}
    //             </Tag>
    //         );
    //     }
    // },

    {
      title: "상태",
      dataIndex: "status",
      key: "status",
      width: "9%",
      render: (value) => {
        let color = "red";
        let stat = null;
        switch (value) {
          case "1":
            stat = "작업 중";
            color = "geekblue";
            break;
          case "2":
            stat = "작업 완료";
            color = "green";
            break;
          case "3":
            stat = "검수 중";
            color = "purple";
            break;
          case "4":
            stat = "검수 완료";
            color = "green";
            break;
          case "5":
            stat = "재작업 요청";
            color = "orange";
            break;
          case "6":
            stat = "재검수 대기";
            color = "cyan";
            break;
          default:
            stat = "error";
            color = "red";
        }
        return (
          <Tag color={color} style={{ fontSize: "14px" }}>
            {stat}
          </Tag>
        );
      },
    },

    {
      title: "작업자",
      dataIndex: "workId",
      key: "workId",
      width: "8%",
    },

    {
      title: "검수자",
      dataIndex: "insp1Id",
      key: "insp1Id",
      width: "8%",
    },

    {
      title: "전체 파일 수",
      dataIndex: "fileCount",
      key: "fileCount",
      width: "8.8%",
    },
    {
      title: "관리",
      key: "taskFolderNo",
      width: "6%",
      render: (value, record) => {
        return (
          <>
            <Button
              style={{ marginBottom: "6px" }}
              onClick={() => onJobRestart(value)}
            >
              작업 보기
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <PageHeader className="site-page-header" title="작업 및 검수 목록" />
      <Table
        columns={columns}
        dataSource={myJobAdmin}
        rowKey={(record) => record.folderNo}
        loading={isLoading}
      />
    </>
  );
};

export default JobAdmin;
