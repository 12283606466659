import React, { useEffect, useState } from "react";
import { message, PageHeader, Table, Tag, Button } from "antd";
import { useHistory } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { forEach } from "jszip";

/**
 * 내 완료된 작업
 * @returns {JSX.Element}
 * @constructor
 */
const JobAdminComplete = ({
  getWorkFolderInfo,
  projectService,
  projectInfo,
  currentUser,
}) => {
  const [adminCompleteJob, setAdminCompleteJob] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [taskValue, setTaskValue] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  let projectNo = projectInfo.projectNo;

  useEffect(() => {
    projectService.getJobAdmin(projectNo, "COMPLETED").then((r) => {
      console.log(r);
      setAdminCompleteJob(r.taskFolders);
      setLoading(false);
    });
  }, [projectService]);

  const handleClickOpen = (value) => {
    setTaskValue(value);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onJobRestart = (value) => {
    getWorkFolderInfo({
      ...value,
      isFin: true,
    });

    if (value.fileType == "VIDEO") {
      history.push("/annotationVideoAdmin");
    } else {
      history.push("/annotationImageAdmin");
    }
  };

  const onAdminJobEnd = (taskFolderNo) => {
    handleClose();
    projectService
      .onAdminJobEnd(taskFolderNo)
      .then((r) => {
        message.info("결과 제출 완료 처리되었습니다.");
        projectService.getJobAdmin(projectNo, "COMPLETED").then((res) => {
          console.log(res);
          setAdminCompleteJob(res.taskFolders);
          setLoading(false);
        });
      })
      .catch((e) => {
        if (e.response.status === 429) {
          message.error(
            "이미 작업 결과물을 생성중입니다. 잠시후에 다시 시도하세요."
          );
        } else {
          message.error(e.message);
        }
      });
  };

  const columns = [
    // {
    //   title: "No",
    //   dataIndex: "rowNum",
    //   key: "rowNum",
    //   width: "3%",
    //   align: "center",
    // },

    {
      title: "태스크",
      dataIndex: "taskName",
      key: "taskName",
      width: "8%",
    },
    {
      title: "폴더",
      width: "25%",
      render: (value) => {
        return <>{value.folderPath}</>;
      },
    },

    // {
    //     title: 'AI 선가공 유형',
    //     dataIndex: 'dataType',
    //     key: 'dataType',
    //     width: "7%",
    //     render: (value) => {
    //         let tag = "오류";
    //         let color = "red";
    //         switch (value) {
    //             case "FACE":
    //                 tag = "비식별화(얼굴)";
    //                 color = "geekblue";
    //                 break;
    //             case "OBJ":
    //                 tag = "객체인식";
    //                 color = "green";
    //                 break;
    //             case "SKE17":
    //                 tag = "스켈레톤17";
    //                 color = "purple";
    //                 break;
    //             case "SKE24":
    //                 tag = "스켈레톤24";
    //                 color = "purple";
    //                 break;
    //             case "OCR":
    //                 tag = "OCR";
    //                 color = "orange";
    //                 break;
    //             case "LP":
    //                 tag = "비식별화(번호판)";
    //                 color = "orange";
    //                 break;
    //             case "FL":
    //                 tag = "비식별화";
    //                 color = "orange";
    //                 break;
    //             case "CAPTIONING":
    //                 tag = "캡셔닝";
    //                 color = "green"
    //                 break;
    //             default:
    //                 tag = "선가공 없음";
    //                 color = "darkgray";
    //                 break;
    //         }
    //         return (
    //             <Tag color={color} style={{ fontSize: '14px'}}>
    //                 {tag}
    //             </Tag>
    //         );
    //     }
    // },

    // {
    //     title: '파일유형',
    //     dataIndex: 'fileType',
    //     key: 'fileType',
    //     width: "7%",
    //     render: (value) => {
    //         return (
    //             <Tag color={"cyan"} key={value} style={{ fontSize: '14px'}}>
    //                 {value}
    //             </Tag>
    //         );
    //     }
    // },
    {
      title: "작업자",
      dataIndex: "workId",
      key: "workId",
      width: "8%",
    },
    {
      title: "검수자",
      dataIndex: "insp1Id",
      key: "insp1Id",
      width: "8%",
    },
    {
      title: "전체 파일 수",
      dataIndex: "fileCount",
      key: "fileCount",
      width: "4%",
    },

    {
      title: "관리",
      key: "taskFolderNo",
      width: "2.6%",
      render: (value) => {
        return (
          <>
            <div>
              <Button
                style={{ marginBottom: "6px" }}
                onClick={() => onJobRestart(value)}
              >
                작업 보기
              </Button>
            </div>
            <div>
              {value.isOwner && !value.isProcessing && (
                <Button type="primary" onClick={() => handleClickOpen(value)}>
                  결과 재생성
                </Button>
              )}
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      {/*내 완료된 작업 : USER_ID 가 자신이며 완료된 TASK들을 리스트로 출력하는 페이지.
            입력값 : project_no
            출력값 :
            TASK_NO(정렬기준) ,  task_name, type, 전체 파일개수(task_file 테이블에서), 1차검수 통과수, 2차검수 통과수, 반려수 REG_DATE*/}
      <PageHeader
        className="site-page-header"
        title="완료된 작업 및 검수 목록"
      />
      <Table
        columns={columns}
        dataSource={adminCompleteJob}
        rowKey={(record) => record.taskFolderNo}
        loading={isLoading}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`알림`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {taskValue.taskName} - {taskValue.folderPath} 작업을
            제출하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            취소
          </Button>
          <Button
            onClick={() => onAdminJobEnd(taskValue.taskFolderNo)}
            color="primary"
            autoFocus
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default JobAdminComplete;
