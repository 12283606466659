import React, { memo, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

//antd
import { Table, Tag, PageHeader, Input, Tabs } from "antd";

/**
 * 종료된 프로젝트 목록
 */
const EndProjects = memo(({ projectService, getProjectInfo, currentUser }) => {
  const history = useHistory();
  const [projectList, setProject] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (currentUser.isAdmin) {
      projectService
        .getAdminProjectList("CLOSE")
        .then((r) => {
          console.log(r);
          setProject(r.projects);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      projectService
        .getProjectList("CLOSE")
        .then((r) => {
          console.log(r);
          setProject(r);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [projectService]);

  const handleProjectSelect = (record) => {
    console.log(record);
    getProjectInfo(record);
    history.push(`/projectInfo/`);
  };

  const columns = [
    {
      title: "No",
      dataIndex: "projectNo",
      key: "projectNo",
      width: "5%",
      align: "center",
    },

    {
      title: "프로젝트 제목",
      dataIndex: "title",
      key: "title",
      width: 80,
      render: (text, record) => (
        <Link onClick={() => handleProjectSelect(record)}>{record.title}</Link>
      ),
    },
    {
      title: "프로젝트 설명(내용)",
      dataIndex: "content",
      key: "content",
      width: 80,
    },

    {
      title: "작업자 상태",
      dataIndex: "participantStatus",
      key: "participantStatus",
      width: "10%",
      align: "center",
      render: (value) => {
        let tag = "오류";
        let color = "red";
        if (value === "작업자") {
          tag = value;
          color = "geekblue";
        }
        if (value === "검수자") {
          tag = value;
          color = "green";
        }
        if (value === "관리자") {
          tag = value;
          color = "gray";
        }
        return (
          <Tag color={color} key={tag} style={{ fontSize: "14px" }}>
            {tag}
          </Tag>
        );
      },
    },

    {
      title: "프로젝트 상태",
      dataIndex: "status",
      key: "status",
      width: "10%",
      align: "center",
      render: (value) => {
        let tag = "오류";
        let color = "red";
        if (value === "종료") {
          tag = "종료";
          color = "red";
        }
        if (value === "정지") {
          tag = "정지";
          color = "orange";
        }
        return (
          <Tag color={color} key={tag} style={{ fontSize: "14px" }}>
            {tag}
          </Tag>
        );
      },
    },
    {
      title: "프로젝트 시작일",
      dataIndex: "startDate",
      key: "startDate",
      width: 80,
      align: "center",
    },
    {
      title: "프로젝트 마감일",
      dataIndex: "endDate",
      key: "endDate",
      width: 80,
      align: "center",
    },
  ];

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="종료된 프로젝트 목록"
        extra={[
          <Input.Search
            placeholder="제목 및 작업명 검색"
            allowClear
            enterButton="검색"
            size="large"
            style={{ visibility: "hidden" }}
          />,
        ]}
        subTitle={[
          <Tabs style={{ paddingLeft: "30px", visibility: "hidden" }}>
            <Tabs.TabPane
              tab="-"
              style={{ visibility: "hidden" }}
            ></Tabs.TabPane>
            <Tabs.TabPane
              tab="-"
              style={{ visibility: "hidden" }}
            ></Tabs.TabPane>
          </Tabs>,
        ]}
      />
      <Table
        columns={columns}
        dataSource={projectList}
        rowKey={(record) => record.projectNo}
        loading={isLoading}
      />
    </>
  );
});
export default EndProjects;
