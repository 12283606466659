import React, { memo, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import "moment/locale/ko";
import { Table, Tag, PageHeader, Input, Tabs } from "antd";

/**
 * 프로젝트 목록
 */
const Projects = memo(({ projectService, getProjectInfo, currentUser }) => {
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  const [projectList, setProject] = useState([]);
  const [projectSearchList, setProjectSearch] = useState();

  useEffect(() => {
    if (currentUser.isAdmin) {
      projectService
        .getAdminProjectList("OPEN")
        .then((r) => {
          console.log(r);
          setProject(r.projects);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      projectService
        .getProjectList("OPEN")
        .then((r) => {
          console.log(r);
          setProject(r);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [projectService]);

  const handleProjectSelect = (record) => {
    console.log(record);
    getProjectInfo(record);
    history.push(`/projectInfo`);
  };

  const onSearch = (searchVal) => {
    const result = projectList.filter((data) => {
      // return data.title.indexOf(searchVal) != -1;
      return new RegExp(searchVal, "i").test(data.title);
    });
    setProjectSearch(result);
  };

  const columns = [
    // {
    //     title: 'No',
    //     dataIndex: 'rowNum',
    //     key: 'rowNum',
    //     width: '5%',
    //     align : 'center'
    // },
    {
      title: "프로젝트",
      dataIndex: "title",
      key: "title",
      width: "22%",
      sorter: (a, b) => {
        return a.title < b.title ? -1 : a == b ? 0 : 1;
      },
      ellipsis: true,
      render: (text, record) => (
        <Link onClick={() => handleProjectSelect(record)}>{record.title}</Link>
      ),
    },
    {
      title: "작업구분",
      dataIndex: "participantStatus",
      key: "participantStatus",
      width: "6%",
      align: "center",
      render: (value) => {
        let tag = "오류";
        let color = "red";
        if (value === "작업자") {
          tag = value;
          color = "geekblue";
        }
        if (value === "검수자") {
          tag = value;
          color = "green";
        }
        if (value === "관리자") {
          tag = value;
          color = "gray";
        }
        return (
          <Tag color={color} key={tag} style={{ fontSize: "14px" }}>
            {tag}
          </Tag>
        );
      },
    },
    {
      title: "상태",
      dataIndex: "status",
      key: "status",
      width: "6%",
      align: "center",
      render: (value) => {
        let tag = "오류";
        let color = "red";
        if (value === "대기") {
          tag = "대기";
          color = "geekblue";
        }
        if (value === "진행") {
          tag = "진행";
          color = "green";
        }
        return (
          <Tag color={color} key={tag} style={{ fontSize: "14px" }}>
            {tag}
          </Tag>
        );
      },
    },
    {
      title: "검수횟수",
      dataIndex: "inspMethod",
      key: "inspMethod",
      width: "6%",
      align: "center",
      render: (value) => {
        let method = "오류";
        if (value === 0) {
          method = "없음";
        }
        if (value === 1) {
          method = "1회";
        }
        if (value === 2) {
          method = "2회";
        }
        return <>{method}</>;
      },
    },
    {
      title: "작업대기",
      dataIndex: "waitingTaskFolderCount",
      key: "waitingTaskFolderCount",
      sorter: (a, b) => b.waitingTaskFolderCount - a.waitingTaskFolderCount,
      width: "6%",
      align: "center",
    },
    {
      title: "작업중",
      dataIndex: "processingTaskFolderCount",
      key: "processingTaskFolderCount",
      sorter: (a, b) =>
        b.processingTaskFolderCount - a.processingTaskFolderCount,
      width: "6%",
      align: "center",
    },
    {
      title: "프로젝트 시작일",
      dataIndex: "startDate",
      key: "startDate",
      sorter: (a, b) => {
        const aa = moment(a.startDate);
        const bb = moment(b.startDate);
        return aa.diff(bb, "days");
      },
      width: "10%",
      align: "center",
    },
    {
      title: "프로젝트 마감일",
      dataIndex: "endDate",
      key: "endDate",
      sorter: (a, b) => {
        const aa = moment(a.endDate);
        const bb = moment(b.endDate);
        return aa.diff(bb, "days");
      },
      width: "10%",
      align: "center",
    },
  ];

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="프로젝트 목록"
        extra={[
          <Input.Search
            placeholder="제목 검색"
            allowClear
            enterButton="검색"
            size="large"
            onSearch={onSearch}
          />,
        ]}
        subTitle={[
          <Tabs style={{ paddingLeft: "30px", visibility: "hidden" }}>
            <Tabs.TabPane
              tab="-"
              style={{ visibility: "hidden" }}
            ></Tabs.TabPane>
            <Tabs.TabPane
              tab="-"
              style={{ visibility: "hidden" }}
            ></Tabs.TabPane>
          </Tabs>,
        ]}
      />
      <Table
        columns={columns}
        dataSource={projectSearchList ? projectSearchList : projectList}
        rowKey={(record) => record.projectNo}
        showSorterTooltip={false}
        loading={isLoading}
      />
    </>
  );
});
export default Projects;
