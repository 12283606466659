export default class AuthService {
  constructor(http, tokenStorage) {
    this.http = http;
    this.tokenStorage = tokenStorage;
  }

  async signup(username, password, name, email, url) {
    const data = await this.http.axios('/auth/signup', {
      method: 'POST',
      data: {
        username,
        password,
        name,
        email,
        url,
      },
    });
    this.tokenStorage.saveToken(data.accessToken);
    return data;
  }

  async login(userId, userPw) {

    const data = await this.http.axios('/auth/signin', {
      method: 'POST',
      data: { userId, userPw },
    });
    this.tokenStorage.saveToken(data.accessToken);
    return data;
  }

  async logout() {
    this.tokenStorage.clearToken();
  }
}
