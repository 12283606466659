import React, { useEffect, useState } from "react";
import { PageHeader, Form, Input, Button, Upload } from "antd";
import { Link, useHistory, useParams } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";
import Loading from "../../common/Loading";

const CustomForm = ({ formItemLayout, buttonItemLayout, onFinish, fields }) => (
  <Form {...formItemLayout} name="qnaEdit" fields={fields} onFinish={onFinish}>
    <Form.Item
      name="title"
      label="제목"
      rules={[
        {
          required: true,
          message: "제목을 입력해 주세요.",
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item name="content" label="내용">
      <Input.TextArea showCount rows={6} maxLength={1000} />
    </Form.Item>
    <Form.Item {...buttonItemLayout}>
      <Button type="primary" htmlType="submit">
        수정
      </Button>
    </Form.Item>
  </Form>
);

/**
 * 묻고 답하기
 * @returns {JSX.Element}
 * @constructor
 */
const QnaEdit = ({ articleService, projectInfo }) => {
  let projectNo = projectInfo.projectNo;
  const { questionId } = useParams();
  const history = useHistory();

  const [qnaView, setQnaView] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    articleService.getQnaView(projectNo, questionId).then((r) => {
      console.log(r);
      setQnaView([
        {
          name: "title",
          value: r.title,
        },
        {
          name: "content",
          value: r.content,
        },
      ]);
      setLoading(false);
    });
  }, [articleService]);

  const onFinish = (values) => {
    var content = {
      questionId: questionId,
      title: values.title,
      content: values.content,
    };

    articleService.patchQuestion(projectNo, content).then((res) => {
      history.push(`/qnaList`);
    });
  };

  const formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 14,
    },
  };

  const buttonItemLayout = {
    wrapperCol: {
      span: 14,
      offset: 4,
    },
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <CustomForm
      formItemLayout={formItemLayout}
      buttonItemLayout={buttonItemLayout}
      onFinish={onFinish}
      fields={qnaView}
    />
  );
};

export default QnaEdit;
