import React, { useEffect, useState } from "react";
import { Row, Col, Descriptions, PageHeader } from "antd";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveLine } from "@nivo/line";
import { Typography } from "antd";
import { Skeleton } from "@material-ui/lab";

const { Title } = Typography;
/**
 * 프로젝트 정보확인
 */

const ProjectInfo = ({ projectInfo, projectService, currentUser }) => {
  let project = projectInfo;
  const [isLoading, setLoading] = useState(true);
  const [dailyList, setDailyList] = useState([]);
  const [accumulatedList, setAccumulatedList] = useState([]);
  const [projectDetail, setProjectDetail] = useState([]);

  useEffect(() => {
    if (currentUser.isAdmin) {
      projectService.adminProjectInfo(project.projectNo).then((info) => {
        console.log(info);
        setProjectDetail(info.project);
        setLoading(false);
      });
    } else {
      projectService.userProjectInfo(project.projectNo).then((info) => {
        console.log(info);
        setProjectDetail(info.project);
        projectService.userProjectInfo(project.projectNo).then((res) => {
          //console.log(res);
          if (res.statistics) {
            let accumulatedGraph = res.statistics.accumulatedGraph;
            let accumulateData = accumulatedGraph.map((val) => {
              return {
                x: val.date.substring(5, 10),
                y: val.accumulatedWorkCount,
              };
            });
            let dailyGraph = res.statistics.dailyGraph;
            let dailyData = dailyGraph.map((val) => {
              return {
                date: val.date.substring(5, 10),
                dailyWorkCount: val.dailyWorkCount,
              };
            });
            setDailyList(dailyData.slice(0, 14));
            setAccumulatedList([
              { id: "누적 작업", data: accumulateData.slice(0, 14) },
            ]);
          }
          setLoading(false);
        });
      });
    }
  }, [projectService]);

  return (
    <>
      {isLoading ? (
        <div>
          <Skeleton
            variant="text"
            width={200}
            height={40}
            style={{ marginLeft: "20px", marginTop: "15px" }}
          />
          <Skeleton
            variant="rect"
            width={1610}
            height={55}
            style={{ marginBottom: "2px", marginTop: "20px" }}
          />
          <Skeleton
            variant="rect"
            width={1610}
            height={55}
            style={{ marginBottom: "2px" }}
          />
          <Skeleton variant="rect" width={1610} height={55} />
        </div>
      ) : (
        <>
          <>
            <PageHeader
              className="site-page-header"
              title={projectDetail.title}
            />
            <Descriptions
              bordered
              labelStyle={{ backgroundColor: "#001529", color: "#FFF" }}
              contentStyle={{ borderColor: "#000" }}
            >
              <Descriptions.Item label="프로젝트 명칭" span={2}>
                {projectDetail.title}
              </Descriptions.Item>
              <Descriptions.Item label="상태" span={1}>
                {projectDetail.status}
              </Descriptions.Item>
              <Descriptions.Item label="프로젝트 설명" span={5}>
                {projectDetail.content}
              </Descriptions.Item>
              <Descriptions.Item label="프로젝트 기간" span={3}>
                <Row>
                  <Col span={8}>시작일 : {projectDetail.startDate}</Col>
                  <Col span={8}>종료일 : {projectDetail.endDate}</Col>
                  <Col span={8}>남은 기간 : {projectDetail.remainingDate}</Col>
                </Row>
              </Descriptions.Item>
            </Descriptions>
          </>

          <br />
          {(projectDetail.mngrName ||
            projectDetail.mngrPhone1 ||
            projectDetail.mngrEmail1) && (
            <>
              <PageHeader className="site-page-header" title="담당자 정보" />
              <Descriptions
                bordered
                labelStyle={{
                  backgroundColor: "#001529",
                  flex: 1,
                  color: "#FFF",
                }}
              >
                {projectDetail.mngrName && (
                  <Descriptions.Item label="담당자 이름">
                    {" "}
                    {projectDetail.mngrName}{" "}
                  </Descriptions.Item>
                )}
                {projectDetail.mngrPhone1 && (
                  <Descriptions.Item label="전화번호" span={2}>
                    {" "}
                    {projectDetail.mngrPhone1}
                  </Descriptions.Item>
                )}
                {projectDetail.mngrEmail1 && (
                  <Descriptions.Item label="이메일주소" span={3}>
                    {projectDetail.mngrEmail1}
                    {projectDetail.mngrEmail2 ? "@" : ""}
                    {projectDetail.mngrEmail2}
                  </Descriptions.Item>
                )}
              </Descriptions>
            </>
          )}
        </>
      )}
      {accumulatedList.length > 0 && accumulatedList[0].data.length > 0 && (
        <Row style={{ marginTop: "25px" }}>
          <Col flex={1} style={{ height: "400px" }}>
            <Title level={4}>일별 차트</Title>
            <ResponsiveBar
              data={dailyList}
              keys={["dailyWorkCount"]}
              indexBy="date"
              margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
              padding={0.3}
              valueScale={{ type: "linear" }}
              indexScale={{ type: "band", round: true }}
              colors={{ scheme: "nivo" }}
              colorBy={"indexValue"}
              defs={[
                {
                  id: "dots",
                  type: "patternDots",
                  background: "inherit",
                  color: "#38bcb2",
                  size: 4,
                  padding: 1,
                  stagger: true,
                },
                {
                  id: "lines",
                  type: "patternLines",
                  background: "inherit",
                  color: "#eed312",
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10,
                },
              ]}
              tooltip={({ value, indexValue, color }) => (
                <div
                  style={{
                    padding: 12,
                    color: "#FFFFFF",
                    background: "#222222",
                  }}
                >
                  {indexValue} : {value} 건
                </div>
              )}
              borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: "bottom",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "날짜",
                legendOffset: 36,
                legendPosition: "middle",
              }}
              axisLeft={{
                orient: "left",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "작업 수",
                legendOffset: -40,
                legendPosition: "middle",
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              role="application"
            />
          </Col>
          <Col flex={1} style={{ height: "400px" }}>
            <Title level={4}>누적 차트</Title>
            <ResponsiveLine
              data={accumulatedList}
              margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
              xScale={{ type: "point" }}
              yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
                stacked: true,
                reverse: false,
              }}
              yFormat=" >-.2c"
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: "bottom",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "날짜",
                legendOffset: 36,
                legendPosition: "middle",
              }}
              axisLeft={{
                orient: "left",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "작업 수",
                legendOffset: -40,
                legendPosition: "middle",
              }}
              pointSize={10}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              useMesh={true}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default ProjectInfo;
