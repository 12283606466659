import React, { useEffect, useState } from "react";
import { message, PageHeader, Table, Tag, Button } from "antd";
import { useHistory } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

/**
 * 내 완료된 검수
 * @returns {JSX.Element}
 * @constructor
 */
const InspcCompleteList = ({ projectService, projectInfo }) => {
  const [myCompletedInspection, setMyCompletedInspection] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [taskValue, setTaskValue] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  let projectNo = projectInfo.projectNo;

  useEffect(() => {
    projectService.getMyCompletedInspection(projectNo).then((r) => {
      console.log(r);
      setMyCompletedInspection(r);
      setLoading(false);
    });
  }, [projectService]);

  const handleClickOpen = (value) => {
    setTaskValue(value);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //검수 완료
  const onInspectComplete = (taskFolderNo, role) => {
    handleClose();
    projectService
      .onJobEnd(projectNo, taskFolderNo, role)
      .then((r) => {
        message.info("재검수 완료 처리되었습니다.");
        history.push("/inspcCompleteList");
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  const columns = [
    // {
    //   title: "No",
    //   dataIndex: "rowNum",
    //   key: "rowNum",
    //   width: "5%",
    //   align: "center",
    // },

    {
      title: "태스크",
      dataIndex: "taskName",
      key: "taskName",
      width: "5%",
    },
    {
      title: "폴더",
      width: "35%",
      render: (value) => {
        return <>{value.folderPath}</>;
      },
    },

    // {
    //     title: 'AI 선가공 유형',
    //     dataIndex: 'dataType',
    //     key: 'dataType',
    //     width: "7%",
    //     render: (value) => {
    //         let tag = "오류";
    //         let color = "red";
    //         switch (value) {
    //             case "FACE":
    //                 tag = "비식별화(얼굴)";
    //                 color = "geekblue";
    //                 break;
    //             case "OBJ":
    //                 tag = "객체인식";
    //                 color = "green";
    //                 break;
    //             case "SKE17":
    //                 tag = "스켈레톤17";
    //                 color = "purple";
    //                 break;
    //             case "SKE24":
    //                 tag = "스켈레톤24";
    //                 color = "purple";
    //                 break;
    //             case "OCR":
    //                 tag = "OCR";
    //                 color = "orange";
    //                 break;
    //             case "LP":
    //                 tag = "비식별화(번호판)";
    //                 color = "orange";
    //                 break;
    //             case "FL":
    //                 tag = "비식별화";
    //                 color = "orange";
    //                 break;
    //             case "CAPTIONING":
    //                 tag = "캡셔닝";
    //                 color = "green"
    //                 break;
    //             default:
    //                 tag = "선가공 없음";
    //                 color = "darkgray";
    //                 break;
    //         }
    //         return (
    //             <Tag color={color} style={{ fontSize: '14px'}}>
    //                 {tag}
    //             </Tag>
    //         );
    //     }
    // },
    // {
    //     title: '파일유형',
    //     dataIndex: 'fileType',
    //     key: 'fileType',
    //     width: "7%",
    //     render: (value) => {
    //         return (
    //             <Tag color={"cyan"} key={value} style={{ fontSize: '14px'}}>
    //                 {value}
    //             </Tag>
    //         );
    //     }
    // },
    {
      title: "상태",
      dataIndex: "status",
      key: "status",
      width: "8%",
      render: (value) => {
        let color = "red";
        let stat = null;
        switch (value) {
          case "1":
            stat = "작업 중";
            color = "geekblue";
            break;
          case "2":
            stat = "작업 완료";
            color = "green";
            break;
          case "3":
            stat = "검수 중";
            color = "purple";
            break;
          case "4":
            stat = "검수 완료";
            color = "green";
            break;
          case "5":
            stat = "재작업 요청";
            color = "orange";
            break;
          case "6":
            stat = "재검수 대기";
            color = "cyan";
            break;
          default:
            stat = "error";
            color = "red";
        }
        return (
          <Tag color={color} style={{ fontSize: "14px" }}>
            {stat}
          </Tag>
        );
      },
    },
    {
      title: "작업자",
      dataIndex: "workId",
      key: "workId",
      width: "8%",
    },
    {
      title: "전체 파일 수",
      dataIndex: "fileCount",
      key: "fileCount",
      width: "9%",
    },
    // {
    //   title: "검수 완료 수",
    //   dataIndex: "firstInspectionCount",
    //   key: "firstInspectionCount",
    //   width: "9%",
    // },
    {
      title: "반려수",
      dataIndex: "rejectedCount",
      key: "rejectedCount",
      width: "8%",
    },
  ];

  return (
    <>
      {/*내 완료된 검수 : 자신이 inspector인 1,2차검수완료(1차의 경우 2차 완료시에 포함된다) TASK들을 리스트로 출력하는 페이지.
            입력값 : project_no
            출력값 :
            TASK_NO(정렬기준) ,  task_name, type, 파일개수(task_file 테이블에서), REG_DATE*/}
      <PageHeader className="site-page-header" title="내 완료된 검수" />
      <Table
        columns={columns}
        dataSource={myCompletedInspection}
        rowKey={(record) => record.taskFolderNo}
        loading={isLoading}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`알림`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {taskValue.taskName} 재검수를 완료 하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            취소
          </Button>
          <Button
            onClick={() =>
              onInspectComplete(taskValue.taskFolderNo, taskValue.role)
            }
            color="primary"
            autoFocus
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InspcCompleteList;
