import React from "react";

//img
import Logo from "../../img/logo.png";
import manual from "../../img/manual_CVLabel.pptx";

//antd
import { Layout, Menu, Row, Col, Button } from "antd";
import { DownloadOutlined, LogoutOutlined } from "@ant-design/icons";

const { Header } = Layout;

/* 미사용 메뉴
<Menu.Item key="3" onClick={onProjectRequest}>프로젝트 신청</Menu.Item>
*/

function HeaderHtml(props) {
  const {
    onEndProject,
    onPLojectList,
    onProjectStat,
    onLogout,
    currentUser,
  } = props;
  return (
    <Header className="header">
      <Row>
        <Col
          flex={1}
          style={{
            minWidth: "120px",
          }}
        >
          <div className="logo">
            <img src={Logo} />
          </div>
        </Col>
        <Col flex={3}>
          <Menu theme="dark" mode="horizontal" defaultSelectedKeys="1">
            <Menu.Item key="1" onClick={onPLojectList}>
              프로젝트 목록
            </Menu.Item>
            {currentUser.isAdmin == false && (
              <Menu.Item key="3" onClick={onProjectStat}>
                내 프로젝트
              </Menu.Item>
            )}
            <Menu.Item key="2" onClick={onEndProject}>
              종료된 프로젝트
            </Menu.Item>
          </Menu>
        </Col>
        <Col flex={4} />
        <Col flex={2}>
          <span style={{ color: "rgba(255, 255, 255, 0.65)" }}>
            {currentUser.userId} 님{" "}
          </span>
        </Col>
        <Col>
          <Button
            type="text"
            icon={<LogoutOutlined />}
            style={{ color: "rgba(255, 255, 255, 0.65)" }}
            onClick={onLogout}
          >
            로그아웃
          </Button>
        </Col>
      </Row>
    </Header>
  );
}

export default HeaderHtml;
