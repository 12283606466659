export const Definitions = {
    "SKE24" : {
      "landmarks":{
        "Nose" : {"defaultPosition" : [0,-0.376]},
        "Neck" : {"defaultPosition" : [0,-0.295]},
        "RShoulder" : {"defaultPosition" : [-0.07,-0.255]},
        "RElbow" : {"defaultPosition" : [-0.127,-0.108]},
        "RWrist" : {"defaultPosition" : [-0.143,0.067]},
        "LShoulder" : {"defaultPosition" : [0.07,-0.255]},
        "LElbow" : {"defaultPosition" : [0.127,-0.108]},
        "LWrist" : {"defaultPosition" : [0.143,0.067]},
        "MidHip" : {"defaultPosition" : [0,0.015]},
        "RHip" : {"defaultPosition" : [-0.05,0.052]},
        "RKnee" : {"defaultPosition" : [-0.07,0.234]},
        "RAnkle" : {"defaultPosition" : [-0.08,0.374]},
        "LHip" : {"defaultPosition" : [0.05,0.052]},
        "LKnee" : {"defaultPosition" : [0.07,0.234]},
        "LAnkle" : {"defaultPosition" : [0.08,0.374]},
        "REye" : {"defaultPosition" : [-0.02,-0.433]},
        "LEye" : {"defaultPosition" : [0.02,-0.433]},
        "REar" : {"defaultPosition" : [-0.05,-0.388]},
        "LEar" : {"defaultPosition" : [0.05,-0.388]},
        "LBigToe" : {"defaultPosition" : [0.085,0.404]},
        "LSmallToe" : {"defaultPosition" : [0.107,0.394]},
        "LHeel" : {"defaultPosition" : [0.05,0.379]},
        "RBigToe" : {"defaultPosition" : [-0.085,0.404]},
        "RSmallToe" : {"defaultPosition" : [-0.107,0.394]},
        "RHeel" : {"defaultPosition" : [-0.05,0.379]}
      },
      "connections": [
        ["Nose","Neck"],
        ["Nose","REye"],
        ["Nose","LEye"],
        ["Neck","RShoulder"],
        ["Neck","LShoulder"],
        ["Neck","MidHip"],
        ["RShoulder","RElbow"],
        ["RElbow","RWrist"],
        ["LShoulder","LElbow"],
        ["LElbow","LWrist"],
        ["MidHip","RHip"],
        ["MidHip","LHip"],
        ["RHip","RKnee"],
        ["RKnee","RAnkle"],
        ["LHip","LKnee"],
        ["LKnee","LAnkle"],
        ["REye","REar"],
        ["LEye","LEar"],
        ["LAnkle","LBigToe"],
        ["LBigToe","LSmallToe"],
        ["LAnkle","LHeel"],
        ["RAnkle","RBigToe"],
        ["RBigToe","RSmallToe"],
        ["RAnkle","RHeel"]
      ]
    },
    "SKE17" : {
      "landmarks":{
        "Nose" : {"defaultPosition" : [0,-0.376]},
        "Neck" : {"defaultPosition" : [0,-0.295]},
        "RShoulder" : {"defaultPosition" : [-0.07,-0.255]},
        "RElbow" : {"defaultPosition" : [-0.127,-0.108]},
        "RWrist" : {"defaultPosition" : [-0.143,0.067]},
        "LShoulder" : {"defaultPosition" : [0.07,-0.255]},
        "LElbow" : {"defaultPosition" : [0.127,-0.108]},
        "LWrist" : {"defaultPosition" : [0.143,0.067]},
        "RHip" : {"defaultPosition" : [-0.05,0.052]},
        "RKnee" : {"defaultPosition" : [-0.07,0.234]},
        "RAnkle" : {"defaultPosition" : [-0.08,0.374]},
        "LHip" : {"defaultPosition" : [0.05,0.052]},
        "LKnee" : {"defaultPosition" : [0.07,0.234]},
        "LAnkle" : {"defaultPosition" : [0.08,0.374]},
        "REye" : {"defaultPosition" : [-0.02,-0.433]},
        "LEye" : {"defaultPosition" : [0.02,-0.433]},
        "REar" : {"defaultPosition" : [-0.05,-0.388]},
        "LEar" : {"defaultPosition" : [0.05,-0.388]},
      },
      "connections": [
        ["Nose","Neck"],
        ["Nose","REye"],
        ["Nose","LEye"],
        ["Neck","RShoulder"],
        ["Neck","LShoulder"],
        ["RShoulder","RElbow"],
        ["RElbow","RWrist"],
        ["LShoulder","LElbow"],
        ["LElbow","LWrist"],
        ["Neck","RHip"],
        ["Neck","LHip"],
        ["RHip","RKnee"],
        ["RKnee","RAnkle"],
        ["LHip","LKnee"],
        ["LKnee","LAnkle"],
        ["REye","REar"],
        ["LEye","LEar"]
      ]
    },
    "Rhand" : {
      "landmarks":{
        "WRIST" : {"defaultPosition" : [0,0.4]},
        "THUMB_CMC" : {"defaultPosition" : [-0.1,0.27]},
        "THUMB_MCP" : {"defaultPosition" : [-0.11,0.13]},
        "THUMB_IP" : {"defaultPosition" : [-0.12,-0.01]},
        "THUMB_TIP" : {"defaultPosition" : [-0.13,-0.15]},
        "INDEX_FINGER_MCP" : {"defaultPosition" : [-0.05,-0.04]},
        "INDEX_FINGER_PIP" : {"defaultPosition" : [-0.05,-0.16]},
        "INDEX_FINGER_DIP" : {"defaultPosition" : [-0.05,-0.28]},
        "INDEX_FINGER_TIP" : {"defaultPosition" : [-0.05,-0.40]},
        "MIDDLE_FINGER_MCP" : {"defaultPosition" : [0,-0.04]},
        "MIDDLE_FINGER_PIP" : {"defaultPosition" : [0,-0.16]},
        "MIDDLE_FINGER_DIP" : {"defaultPosition" : [0,-0.28]},
        "MIDDLE_FINGER_TIP" : {"defaultPosition" : [0,-0.40]},
        "RING_FINGER_MCP" : {"defaultPosition" : [0.05,-0.04]},
        "RING_FINGER_PIP" : {"defaultPosition" : [0.05,-0.16]},
        "RING_FINGER_DIP" : {"defaultPosition" : [0.05,-0.28]},
        "RING_FINGER_TIP" : {"defaultPosition" : [0.05,-0.40]},
        "PINKY_MCP" : {"defaultPosition" : [0.1,-0.04]},
        "PINKY_PIP" : {"defaultPosition" : [0.1,-0.16]},
        "PINKY_DIP" : {"defaultPosition" : [0.1,-0.28]},
        "PINKY_TIP" : {"defaultPosition" : [0.1,-0.40]},
      },
      "connections": [
        ["WRIST","THUMB_CMC"],
        ["WRIST","INDEX_FINGER_MCP"],
        ["WRIST","MIDDLE_FINGER_MCP"],
        ["WRIST","RING_FINGER_MCP"],
        ["WRIST","PINKY_MCP"],
        ["THUMB_CMC","THUMB_MCP"],
        ["THUMB_MCP","THUMB_IP"],
        ["THUMB_IP","THUMB_TIP"],
        ["INDEX_FINGER_MCP","INDEX_FINGER_PIP"],
        ["INDEX_FINGER_PIP","INDEX_FINGER_DIP"],
        ["INDEX_FINGER_DIP","INDEX_FINGER_TIP"],
        ["MIDDLE_FINGER_MCP","MIDDLE_FINGER_PIP"],
        ["MIDDLE_FINGER_PIP","MIDDLE_FINGER_DIP"],
        ["MIDDLE_FINGER_DIP","MIDDLE_FINGER_TIP"],
        ["RING_FINGER_MCP","RING_FINGER_PIP"],
        ["RING_FINGER_PIP","RING_FINGER_DIP"],
        ["RING_FINGER_DIP","RING_FINGER_TIP"],
        ["PINKY_MCP","PINKY_PIP"],
        ["PINKY_PIP","PINKY_DIP"],
        ["PINKY_DIP","PINKY_TIP"]
      ]
    },
    "Lhand" : {
      "landmarks":{
        "WRIST" : {"defaultPosition" : [0,0.4]},
        "THUMB_CMC" : {"defaultPosition" : [0.1,0.27]},
        "THUMB_MCP" : {"defaultPosition" : [0.11,0.13]},
        "THUMB_IP" : {"defaultPosition" : [0.12,-0.01]},
        "THUMB_TIP" : {"defaultPosition" : [0.13,-0.15]},
        "INDEX_FINGER_MCP" : {"defaultPosition" : [0.05,-0.04]},
        "INDEX_FINGER_PIP" : {"defaultPosition" : [0.05,-0.16]},
        "INDEX_FINGER_DIP" : {"defaultPosition" : [0.05,-0.28]},
        "INDEX_FINGER_TIP" : {"defaultPosition" : [0.05,-0.40]},
        "MIDDLE_FINGER_MCP" : {"defaultPosition" : [0,-0.04]},
        "MIDDLE_FINGER_PIP" : {"defaultPosition" : [0,-0.16]},
        "MIDDLE_FINGER_DIP" : {"defaultPosition" : [0,-0.28]},
        "MIDDLE_FINGER_TIP" : {"defaultPosition" : [0,-0.40]},
        "RING_FINGER_MCP" : {"defaultPosition" : [-0.05,-0.04]},
        "RING_FINGER_PIP" : {"defaultPosition" : [-0.05,-0.16]},
        "RING_FINGER_DIP" : {"defaultPosition" : [-0.05,-0.28]},
        "RING_FINGER_TIP" : {"defaultPosition" : [-0.05,-0.40]},
        "PINKY_MCP" : {"defaultPosition" : [-0.1,-0.04]},
        "PINKY_PIP" : {"defaultPosition" : [-0.1,-0.16]},
        "PINKY_DIP" : {"defaultPosition" : [-0.1,-0.28]},
        "PINKY_TIP" : {"defaultPosition" : [-0.1,-0.40]},
      },
      "connections": [
        ["WRIST","THUMB_CMC"],
        ["WRIST","INDEX_FINGER_MCP"],
        ["WRIST","MIDDLE_FINGER_MCP"],
        ["WRIST","RING_FINGER_MCP"],
        ["WRIST","PINKY_MCP"],
        ["THUMB_CMC","THUMB_MCP"],
        ["THUMB_MCP","THUMB_IP"],
        ["THUMB_IP","THUMB_TIP"],
        ["INDEX_FINGER_MCP","INDEX_FINGER_PIP"],
        ["INDEX_FINGER_PIP","INDEX_FINGER_DIP"],
        ["INDEX_FINGER_DIP","INDEX_FINGER_TIP"],
        ["MIDDLE_FINGER_MCP","MIDDLE_FINGER_PIP"],
        ["MIDDLE_FINGER_PIP","MIDDLE_FINGER_DIP"],
        ["MIDDLE_FINGER_DIP","MIDDLE_FINGER_TIP"],
        ["RING_FINGER_MCP","RING_FINGER_PIP"],
        ["RING_FINGER_PIP","RING_FINGER_DIP"],
        ["RING_FINGER_DIP","RING_FINGER_TIP"],
        ["PINKY_MCP","PINKY_PIP"],
        ["PINKY_PIP","PINKY_DIP"],
        ["PINKY_DIP","PINKY_TIP"]
      ]
    },
    "ANIMAL20" : {
      "landmarks":{
        "LEFT_EAR" : {"defaultPosition" : [0.11,-0.3]},
        "RIGHT_EAR" : {"defaultPosition" : [0.09,-0.3]},
        "LEFT_EYE" : {"defaultPosition" : [0.11,-0.2]},
        "RIGHT_EYE" : {"defaultPosition" : [0.09,-0.2]},
        "NOSE" : {"defaultPosition" : [0.10,-0.13]},
        "THROAT" : {"defaultPosition" : [0.08,0]},
        "WITHERS" : {"defaultPosition" : [0.05,0]},
        "FRONT_LEFT_ELBOW" : {"defaultPosition" : [0.07,0.14]},
        "FRONT_RIGHT_ELBOW" : {"defaultPosition" : [0.03,0.14]},
        "FRONT_LEFT_KNEE" : {"defaultPosition" : [0.07,0.27]},
        "FRONT_RIGHT_KNEE" : {"defaultPosition" : [0.03,0.27]},
        "FRONT_LEFT_PAW" : {"defaultPosition" : [0.07,0.4]},
        "FRONT_RIGHT_PAW" : {"defaultPosition" : [0.03,0.4]},
        "TAILBASE" : {"defaultPosition" : [-0.05,0]},
        "BACK_LEFT_ELBOW" : {"defaultPosition" : [-0.03,0.14]},
        "BACK_RIGHT_ELBOW" : {"defaultPosition" : [-0.07,0.14]},
        "BACK_LEFT_KNEE" : {"defaultPosition" : [-0.03,0.27]},
        "BACK_RIGHT_KNEE" : {"defaultPosition" : [-0.07,0.27]},
        "BACK_LEFT_PAW" : {"defaultPosition" : [-0.03,0.4]},
        "BACK_RIGHT_PAW" : {"defaultPosition" : [-0.07,0.4]},
      },
      "connections": [
        ["LEFT_EAR","LEFT_EYE"],
        ["LEFT_EYE","NOSE"],
        ["RIGHT_EAR","RIGHT_EYE"],
        ["RIGHT_EYE","NOSE"],
        ["LEFT_EYE","RIGHT_EYE"],
        ["NOSE","THROAT"],
        ["THROAT","WITHERS"],
        ["WITHERS","FRONT_LEFT_ELBOW"],
        ["WITHERS","FRONT_RIGHT_ELBOW"],
        ["FRONT_LEFT_ELBOW","FRONT_LEFT_KNEE"],
        ["FRONT_RIGHT_ELBOW","FRONT_RIGHT_KNEE"],
        ["FRONT_LEFT_KNEE","FRONT_LEFT_PAW"],
        ["FRONT_RIGHT_KNEE","FRONT_RIGHT_PAW"],
        ["WITHERS","TAILBASE"],
        ["TAILBASE","BACK_LEFT_ELBOW"],
        ["TAILBASE","BACK_RIGHT_ELBOW"],
        ["BACK_LEFT_ELBOW","BACK_LEFT_KNEE"],
        ["BACK_RIGHT_ELBOW","BACK_RIGHT_KNEE"],
        ["BACK_LEFT_KNEE","BACK_LEFT_PAW"],
        ["BACK_RIGHT_KNEE","BACK_RIGHT_PAW"]
      ]
    },
    "FACE68" : {
      "landmarks":{
        "1" : {"defaultPosition" : [-0.15,-0.18]},
        "2" : {"defaultPosition" : [-0.14,-0.05]},
        "3" : {"defaultPosition" : [-0.12,0.05]},
        "4" : {"defaultPosition" : [-0.10,0.13]},
        "5" : {"defaultPosition" : [-0.08,0.21]},
        "6" : {"defaultPosition" : [-0.06,0.28]},
        "7" : {"defaultPosition" : [-0.04,0.34]},
        "8" : {"defaultPosition" : [-0.02,0.38]},
        "9" : {"defaultPosition" : [0,0.4]},
        "10" : {"defaultPosition" : [0.02,0.38]},
        "11" : {"defaultPosition" : [0.04,0.34]},
        "12" : {"defaultPosition" : [0.06,0.28]},
        "13" : {"defaultPosition" : [0.08,0.21]},
        "14" : {"defaultPosition" : [0.10,0.13]},
        "15" : {"defaultPosition" : [0.12,0.05]},
        "16" : {"defaultPosition" : [0.14,-0.05]},
        "17" : {"defaultPosition" : [0.15,-0.18]},
        "18" : {"defaultPosition" : [-0.08,-0.45]},
        "19" : {"defaultPosition" : [-0.065,-0.46]},
        "20" : {"defaultPosition" : [-0.05,-0.47]},
        "21" : {"defaultPosition" : [-0.035,-0.46]},
        "22" : {"defaultPosition" : [-0.02,-0.45]},
        "23" : {"defaultPosition" : [0.02,-0.45]},
        "24" : {"defaultPosition" : [0.035,-0.46]},
        "25" : {"defaultPosition" : [0.05,-0.47]},
        "26" : {"defaultPosition" : [0.065,-0.46]},
        "27" : {"defaultPosition" : [0.08,-0.45]},
        "28" : {"defaultPosition" : [0,-0.31]},
        "29" : {"defaultPosition" : [0,-0.25]},
        "30" : {"defaultPosition" : [0,-0.19]},
        "31" : {"defaultPosition" : [0,-0.13]},
        "32" : {"defaultPosition" : [-0.018,-0.06]},
        "33" : {"defaultPosition" : [-0.009,-0.045]},
        "34" : {"defaultPosition" : [0,-0.03]},
        "35" : {"defaultPosition" : [0.009,-0.045]},
        "36" : {"defaultPosition" : [0.018,-0.06]},
        "37" : {"defaultPosition" : [-0.08,-0.3]},
        "38" : {"defaultPosition" : [-0.06,-0.34]},
        "39" : {"defaultPosition" : [-0.04,-0.34]},
        "40" : {"defaultPosition" : [-0.03,-0.3]},
        "41" : {"defaultPosition" : [-0.04,-0.26]},
        "42" : {"defaultPosition" : [-0.06,-0.26]},
        "43" : {"defaultPosition" : [0.03,-0.3]},
        "44" : {"defaultPosition" : [0.04,-0.34]},
        "45" : {"defaultPosition" : [0.06,-0.34]},
        "46" : {"defaultPosition" : [0.08,-0.3]},
        "47" : {"defaultPosition" : [0.06,-0.26]},
        "48" : {"defaultPosition" : [0.04,-0.26]},
        "49" : {"defaultPosition" : [-0.051,0.17]},
        "50" : {"defaultPosition" : [-0.034,0.125]},
        "51" : {"defaultPosition" : [-0.017,0.08]},
        "52" : {"defaultPosition" : [0,0.08]},
        "53" : {"defaultPosition" : [0.017,0.08]},
        "54" : {"defaultPosition" : [0.034,0.125]},
        "55" : {"defaultPosition" : [0.051,0.17]},
        "56" : {"defaultPosition" : [0.034,0.235]},
        "57" : {"defaultPosition" : [0.017,0.28]},
        "58" : {"defaultPosition" : [0,0.28]},
        "59" : {"defaultPosition" : [-0.017,0.28]},
        "60" : {"defaultPosition" : [-0.034,0.235]},
        "61" : {"defaultPosition" : [-0.034,0.175]},
        "62" : {"defaultPosition" : [-0.017,0.14]},
        "63" : {"defaultPosition" : [0,0.14]},
        "64" : {"defaultPosition" : [0.017,0.14]},
        "65" : {"defaultPosition" : [0.034,0.175]},
        "66" : {"defaultPosition" : [0.017,0.24]},
        "67" : {"defaultPosition" : [0,0.24]},
        "68" : {"defaultPosition" : [-0.017,0.24]},
      },
      "connections": [
        ["1","2"],
        ["2","3"],
        ["3","4"],
        ["4","5"],
        ["5","6"],
        ["6","7"],
        ["7","8"],
        ["8","9"],
        ["9","10"],
        ["10","11"],
        ["11","12"],
        ["12","13"],
        ["13","14"],
        ["14","15"],
        ["15","16"],
        ["16","17"],
        ["18","19"],
        ["19","20"],
        ["20","21"],
        ["21","22"],
        ["23","24"],
        ["24","25"],
        ["25","26"],
        ["26","27"],
        ["28","29"],
        ["29","30"],
        ["30","31"],
        ["32","33"],
        ["33","34"],
        ["34","35"],
        ["35","36"],
        ["37","38"],
        ["38","39"],
        ["39","40"],
        ["40","41"],
        ["41","42"],
        ["42","37"],
        ["43","44"],
        ["44","45"],
        ["45","46"],
        ["46","47"],
        ["47","48"],
        ["48","43"],
        ["49","50"],
        ["50","51"],
        ["51","52"],
        ["52","53"],
        ["53","54"],
        ["54","55"],
        ["55","56"],
        ["56","57"],
        ["57","58"],
        ["58","59"],
        ["59","60"],
        ["60","49"],
        ["61","62"],
        ["62","63"],
        ["63","64"],
        ["64","65"],
        ["65","66"],
        ["66","67"],
        ["67","68"],
        ["68","61"]
      ]
    },
    "EYE28" : {
      "landmarks":{
        "0" : {"defaultPosition" : [-0.14,0.12]},
        "1" : {"defaultPosition" : [-0.09,0.06]},
        "2" : {"defaultPosition" : [-0.05,0]},
        "3" : {"defaultPosition" : [0,-0.05]},
        "4" : {"defaultPosition" : [0.05,-0.06]},
        "5" : {"defaultPosition" : [0.1,-0.06]},
        "6" : {"defaultPosition" : [0.15,0.06]},
        "7" : {"defaultPosition" : [0.2,0.12]},
        "8" : {"defaultPosition" : [0.24,0.2]},
        "9" : {"defaultPosition" : [0.17,0.26]},
        "10" : {"defaultPosition" : [0.11,0.29]},
        "11" : {"defaultPosition" : [0.06,0.32]},
        "12" : {"defaultPosition" : [0.02,0.35]},
        "13" : {"defaultPosition" : [-0.01,0.32]},
        "14" : {"defaultPosition" : [-0.07,0.26]},
        "15" : {"defaultPosition" : [-0.12,0.2]},
        "16" : {"defaultPosition" : [-0.18,-0.08]},
        "17" : {"defaultPosition" : [-0.14,-0.17]},
        "18" : {"defaultPosition" : [-0.10,-0.24]},
        "19" : {"defaultPosition" : [-0.06,-0.29]},
        "20" : {"defaultPosition" : [-0.01,-0.33]},
        "21" : {"defaultPosition" : [0.05,-0.29]},
        "22" : {"defaultPosition" : [0.11,-0.23]},
        "23" : {"defaultPosition" : [0.17,-0.15]},
        "24" : {"defaultPosition" : [0.23,-0.07]},
        "25" : {"defaultPosition" : [0.05,0.1]},
        "26" : {"defaultPosition" : [-0.05,0.15]},
        "27" : {"defaultPosition" : [0.1,0.18]},
      },
      "connections": [
        ["0","1"],
        ["1","2"],
        ["2","3"],
        ["3","4"],
        ["4","5"],
        ["5","6"],
        ["6","7"],
        ["7","8"],
        ["8","9"],
        ["9","10"],
        ["10","11"],
        ["11","12"],
        ["12","13"],
        ["13","14"],
        ["14","15"],
        ["15","0"],
        ["16","17"],
        ["17","18"],
        ["18","19"],
        ["19","20"],
        ["20","21"],
        ["21","22"],
        ["22","23"],
        ["23","24"],
      ]
    },
  }