import React, { useState, useEffect } from "react";

import { Route, useHistory, useLocation } from "react-router-dom";
import HeaderHtml from "./components/common/HeaderHtml";
import { useAuth } from "./context/AuthContext";

//component
import Loading from "./components/common/Loading";

//antd
import { Button, Layout, message } from "antd";
import SideMenu from "./components/common/SideMenu";
import FooterHtml from "./components/common/FooterHtml";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

//view
import ProjectRequest from "./components/views/detail/ProjectRequest.jsx";
import ProjectStat from "./components/views/project/ProjectStat.jsx";
import ProjectInfo from "./components/views/project/ProjectInfo";
import ProjectRequestView from "./components/views/detail/ProjectRequestView";
import JobTrgtList from "./components/views/project/JobTrgtList";
import AnnotationImage from "./components/views/annotation/AnnotationImage";
import AnnotationVideoJob from "./components/views/annotation/AnnotationVideoJob";
import AnnotationVideoInspection from "./components/views/annotation/AnnotationVideoInspection";
import AnnotationImageAdmin from "./components/views/annotation/AnnotationImageAdmin";
import AnnotationVideoAdmin from "./components/views/annotation/AnnotationVideoAdmin";
import JobProgressList from "./components/views/project/JobProgressList";
import JobCompleteList from "./components/views/project/JobCompleteList";
import InspcTrgtsList from "./components/views/project/InspcTrgtsList";
import JobAdmin from "./components/views/project/JobAdmin";
import JobAdminComplete from "./components/views/project/JobAdminComplete";
import InspcProgressList from "./components/views/project/InspcProgressList";
import InspcCompleteList from "./components/views/project/InspcCompleteList";
import AccountList from "./components/views/project/AccountList";
import NoticePage from "./components/views/article/NoticePage";
import QnaList from "./components/views/article/QnaList";
import QnaWrite from "./components/views/article/QnaWrite";
import QnaEdit from "./components/views/article/QnaEdit";
import Projects from "./components/views/project/Projects";
import EndProjects from "./components/views/project/EndProjects";
import QnaDetail from "./components/views/article/QnaDetail";
import NoticeDetail from "./components/views/article/NoticeDetail";
import EndProjectInfo from "./components/views/project/EndProjectInfo";
import AdminUpload from "./components/views/AdminUpload";

//service
import ProjectService from "./service/project";
import AnnotationService from "./service/annotation";
import ArticleService from "./service/article";

const { Header, Content, Footer, Sider } = Layout;

function App({ httpClient, tokenStorage }) {
  const history = useHistory();
  const location = useLocation();
  const { logout, currentUser } = useAuth();

  //service
  const projectService = new ProjectService(httpClient, tokenStorage);
  const annotationService = new AnnotationService(httpClient, tokenStorage);
  const articleService = new ArticleService(httpClient, tokenStorage);

  const [open, setOpen] = React.useState(false);
  const [alertType, setAlertType] = useState(null);
  const [alertMsg, setAlertMsg] = useState(true);
  const [isLoading, setLoading] = useState(true);

  const [projectInfo, setProjectInfo] = useState(
    () => JSON.parse(localStorage.getItem("projectInfo")) || {}
  );
  const getProjectInfo = (record) => {
    setProjectInfo(record);
  };
  useEffect(() => {
    localStorage.setItem("projectInfo", JSON.stringify(projectInfo));
  }, [projectInfo]);

  const [workFolderInfo, setWorkFolderInfo] = useState(
    () => JSON.parse(localStorage.getItem("workFolderInfo")) || {}
  );

  useEffect(() => {
    localStorage.setItem("workFolderInfo", JSON.stringify(workFolderInfo));
  }, [workFolderInfo]);

  const getWorkFolderInfo = (value) => {
    setWorkFolderInfo(value);
  };

  useEffect(() => {
    //message.info("app.jsx projectTitle --> " + projectTitle);
  });

  const onPLojectList = () => {
    history.push("/");
  };

  const onProjectStat = () => {
    history.push("/projectStat");
  };

  const onEndProject = () => {
    history.push("/endProjects");
  };

  const onLogout = () => {
    handleClickOpen("로그아웃 하시겠습니까?", "logout");
  };

  useEffect(() => {
    setLoading(false);
  });

  if (isLoading) {
    return <Loading />;
  }

  const handleClickOpen = (msg, type) => {
    setAlertType(type);
    setAlertMsg(msg);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAlertSubmit = () => {
    if (alertType === "logout") {
      logout();
      history.push("/");
      message.info("로그아웃 되었습니다.");
    }
  };

  let menuOpen = null;
  if (
    location.pathname !== "/" &&
    !location.pathname.includes("projectStat") &&
    !location.pathname.includes("projectRequestView") &&
    !location.pathname.includes("endProjects") &&
    !location.pathname.includes("annotation")
  ) {
    menuOpen = (
      <Sider className="layout-background" width={200}>
        <SideMenu
          location={location}
          projectService={projectService}
          projectInfo={projectInfo}
        />
      </Sider>
    );
  }

  return (
    <Layout style={{ height: "100%" }}>
      {!location.pathname.includes("annotation") ? (
        <Header className="header">
          <HeaderHtml
            onPLojectList={onPLojectList}
            onLogout={onLogout}
            onProjectStat={onProjectStat}
            onEndProject={onEndProject}
            currentUser={currentUser}
          />
        </Header>
      ) : (
        <></>
      )}
      <Content style={{ padding: "0 12px", height: "100%" }}>
        {/*<Alert message="Success Tips" type="success" showIcon />*/}
        <Layout
          className="layout-background"
          style={{ padding: "12px 0", height: "100%" }}
        >
          {menuOpen}
          <Content style={{ margin: "0 12px", height: "100%" }}>
            <div
              className="site-layout-background"
              style={{
                padding: 24,
                minHeight: 360,
                height: "100%",
                overflowY: "scroll",
              }}
            >
              {/*body start*/}
              <Route exact path="/">
                {/*프로젝트 목록*/}
                <Projects
                  projectService={projectService}
                  getProjectInfo={getProjectInfo}
                  currentUser={currentUser}
                />
              </Route>
              <Route exact path="/endProjects">
                {/*종료된 프로젝트 목록*/}
                <EndProjects
                  projectService={projectService}
                  getProjectInfo={getProjectInfo}
                  currentUser={currentUser}
                />
              </Route>
              <Route exact path="/endProject/:projectNo">
                {/*종료된 프로젝트 목록 조회*/}
                <EndProjectInfo projectService={projectService} />
              </Route>
              <Route exact path="/projectRequest">
                {/*프로젝트 신청*/}
                <ProjectRequest projectService={projectService} />
              </Route>
              <Route exact path="/projectStat">
                {/*내 프로젝트*/}
                <ProjectStat projectService={projectService} />
              </Route>
              <Route exact path="/projectInfo">
                {/* 프로젝트 정보 */}
                <ProjectInfo
                  projectInfo={projectInfo}
                  projectService={projectService}
                  currentUser={currentUser}
                />
              </Route>
              <Route exact path="/projectRequestView/:projectNo">
                {/* 프로젝트 신청 상세 */}
                <ProjectRequestView projectService={projectService} />
              </Route>
              <Route exact path="/annotationImage/:isRemain">
                {/*어노테이션 페이지(이미지)*/}
                <AnnotationImage
                  workFolderInfo={workFolderInfo}
                  annotationService={annotationService}
                  projectInfo={projectInfo}
                />
              </Route>
              <Route exact path="/annotationVideoJob">
                {/*어노테이션 페이지(비식별화)*/}
                <AnnotationVideoJob
                  workFolderInfo={workFolderInfo}
                  annotationService={annotationService}
                  projectInfo={projectInfo}
                />
              </Route>
              <Route exact path="/jobTrgtList">
                {/*작업 대상 목록*/}
                <JobTrgtList
                  projectService={projectService}
                  projectInfo={projectInfo}
                  getWorkFolderInfo={getWorkFolderInfo}
                />
              </Route>
              <Route exact path="/jobProgressList">
                {/*내 진행 중 작업*/}
                <JobProgressList
                  projectService={projectService}
                  projectInfo={projectInfo}
                  getWorkFolderInfo={getWorkFolderInfo}
                />
              </Route>
              <Route exact path="/jobCompleteList">
                {/*내 완료된 작업*/}
                <JobCompleteList
                  projectService={projectService}
                  projectInfo={projectInfo}
                />
              </Route>
              <Route exact path="/annotationVideoInspection">
                {/*검수(비디오) 페이지*/}
                <AnnotationVideoInspection
                  workFolderInfo={workFolderInfo}
                  annotationService={annotationService}
                  projectInfo={projectInfo}
                />
              </Route>
              <Route exact path="/annotationImageAdmin">
                {/*관리자 뷰 페이지*/}
                <AnnotationImageAdmin
                  workFolderInfo={workFolderInfo}
                  annotationService={annotationService}
                  projectInfo={projectInfo}
                />
              </Route>
              <Route exact path="/annotationVideoAdmin">
                {/*관리자 뷰(비디오) 페이지*/}
                <AnnotationVideoAdmin
                  workFolderInfo={workFolderInfo}
                  annotationService={annotationService}
                  projectInfo={projectInfo}
                />
              </Route>
              <Route exact path="/inspcTrgtsList">
                {/*검수 대상 목록*/}
                <InspcTrgtsList
                  projectService={projectService}
                  projectInfo={projectInfo}
                  getWorkFolderInfo={getWorkFolderInfo}
                />
              </Route>
              <Route exact path="/inspcProgressList">
                {/*내 진행 중 검수*/}
                <InspcProgressList
                  projectService={projectService}
                  projectInfo={projectInfo}
                  getWorkFolderInfo={getWorkFolderInfo}
                />
              </Route>
              <Route exact path="/inspcCompleteList">
                {/*내 완료된 검수*/}
                <InspcCompleteList
                  projectService={projectService}
                  projectInfo={projectInfo}
                />
              </Route>
              <Route exact path="/jobAdmin">
                {/*내 완료된 검수*/}
                <JobAdmin
                  projectService={projectService}
                  projectInfo={projectInfo}
                  getWorkFolderInfo={getWorkFolderInfo}
                />
              </Route>
              <Route exact path="/jobAdminComplete">
                {/*내 완료된 검수*/}
                <JobAdminComplete
                  projectService={projectService}
                  projectInfo={projectInfo}
                  getWorkFolderInfo={getWorkFolderInfo}
                  currentUser={currentUser}
                />
              </Route>
              <Route exact path="/account">
                {/*정산 관리*/}
                <AccountList
                  projectService={projectService}
                  projectInfo={projectInfo}
                />
              </Route>
              <Route exact path="/qnaList">
                {/*묻고 답하기 목록*/}
                <QnaList
                  articleService={articleService}
                  projectInfo={projectInfo}
                />
              </Route>
              <Route exact path="/qnaWrite">
                {/*묻고 답하기 작성*/}
                <QnaWrite
                  articleService={articleService}
                  projectInfo={projectInfo}
                />
              </Route>
              <Route exact path="/qnaEdit/:questionId">
                {/*묻고 답하기 수정*/}
                <QnaEdit
                  articleService={articleService}
                  projectInfo={projectInfo}
                />
              </Route>
              <Route exact path="/qna/:questionId">
                {/*묻고 답하기 상세조회*/}
                <QnaDetail
                  articleService={articleService}
                  projectInfo={projectInfo}
                />
              </Route>
              <Route exact path="/notice">
                {/*공지사항*/}
                <NoticePage
                  articleService={articleService}
                  projectInfo={projectInfo}
                />
              </Route>
              <Route exact path="/notice/:articleId">
                {/*공지사항 상세조회*/}
                <NoticeDetail
                  articleService={articleService}
                  projectInfo={projectInfo}
                />
              </Route>
              <Route exact path="/adminUpload">
                {/*결과 업로드*/}
                <AdminUpload />
              </Route>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{`알림`}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {alertMsg}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => handleClose()} color="primary">
                    취소
                  </Button>

                  <Button onClick={() => handleAlertSubmit()}>확인</Button>
                </DialogActions>
              </Dialog>
              {/*body end*/}
            </div>
          </Content>
        </Layout>
      </Content>
    </Layout>
  );
}

export default App;
