import React, {useEffect, useState} from 'react';
import {PageHeader, Table, Tag} from "antd";
import {Link} from "react-router-dom";

/**
 * 프로젝트 신청
 * @returns {JSX.Element}
 * @constructor
 */
const ProjectRequest = ({projectService}) => {
    const [projectRequest, setProjectRequest]  = useState([]);


    useEffect(() => {

        projectService.getProjectRequestList().then(r => {
            //console.log(r);
            setProjectRequest(r);
        })

    },[projectService]);

    const columns = [
        {
            title: 'No',
            dataIndex: 'rowNum',
            key: 'rowNum',
            width: '5%',
            align : 'center'
        },

        {
            title: '프로젝트 제목',
            dataIndex: 'title',
            key: 'title',
            width: 80,
            render : (text, record) => (
                <Link to={`/projectRequestView/${record.projectNo}`}>{text}</Link>
            )
        },
        {
            title: '프로젝트 설명(내용)',
            dataIndex: 'content',
            key: 'content',
            width: 80,
            ellipsis: {
                showTitle: true,
            },
        },

        {
            title: '프로젝트 상태',
            dataIndex: 'status',
            key: 'status',
            width: '10%',
            align : 'center',
            render: (value) => {
                let tag = "오류";
                let color = "red";
                if (value === '신청 가능') {
                    tag = value;
                    color = "blue";
                }
                if (value === '승인 대기') {
                    tag = value;
                    color = "green";
                }
                return (
                    <Tag color={color} key={tag} style={{ fontSize: '14px'}}>
                        {tag}
                    </Tag>
                );

            }
        },
        {
            title: '프로젝트 시작일',
            dataIndex: 'startDate',
            key: 'startDate',
            width: 80,
            align : 'center'
        },
        {
            title: '프로젝트 마감일',
            dataIndex: 'endDate',
            key: 'endDate',
            width: 80,
            align : 'center'
        },

    ];


    return (
        <div>
            <PageHeader
                className="site-page-header"
                title="프로젝트 신청"
            />
            <Table columns={columns} dataSource={projectRequest} rowKey={record => record.projectNo}  />
        </div>
    );
};

export default ProjectRequest;
